export default [
  // {
  //   type: 'menu',
  //   show: true,
  //   collapse: true,
  //   name: 'Организации',
  //   path: 'selfcompany',
  //   comp: 'SelfCompanyList',
  //   edit: 'SelfCompanyEdit',
  //   icon: 'cisBuildingBusiness',
  //   items: [
  //     {
  //       type: 'link',
  //       show: true,
  //       name: 'Счета',
  //       path: 'account',
  //       comp: 'SelfCompanyAccountList',
  //       edit: 'SelfCompanyAccountEdit',
  //       icon: 'cisList'
  //     },
  //     {
  //       type: 'link',
  //       show: true,
  //       name: 'Банки',
  //       path: 'bank',
  //       comp: 'SelfCompanyBankList',
  //       edit: 'SelfCompanyBankEdit',
  //       icon: 'cisList'
  //     }
  //   ]
  // },
  // {
  //   type: 'menu',
  //   show: true,
  //   collapse: true,
  //   name: 'Поставщики',
  //   path: 'supplier',
  //   comp: 'SupplierList',
  //   edit: 'SupplierEdit',
  //   icon: 'cisStorage',
  //   items: [
  //     {
  //       type: 'link',
  //       show: true,
  //       name: 'Категории',
  //       path: 'suppcat',
  //       comp: 'SupplierCategoryList',
  //       edit: 'SupplierCategoryEdit',
  //       icon: 'cisList'
  //     }
  //   ]
  // },
  // {
  //   type: 'link',
  //   show: true,
  //   name: 'Клиенты',
  //   path: 'client',
  //   comp: 'CompanyList',
  //   icon: 'cisFolderShared'
  // },
  // { type: 'divide' },
  {
    type: 'group',
    show: true,
    collapse: true,
    name: 'Доходы',
    nameClasses: { 'fw-bold': true },
    path: 'income',
    icon: 'cisPlusCircle',
    items: [
      {
        type: 'link',
        show: true,
        name: 'Общая категория',
        path: 'default',
        comp: 'IncomeList',
        icon: 'cisArrowRight'
      }
    ]
  },
  {
    type: 'group',
    show: true,
    collapse: true,
    name: 'Расходы',
    path: 'expense',
    icon: 'cisMinusCircle',
    items: [
      {
        type: 'link',
        show: true,
        name: 'Вычеты рейсов',
        path: 'deductions',
        comp: 'Deductions',
        icon: 'cisArrowLeft'
      },
      {
        type: 'link',
        show: true,
        name: 'Переменные расходы',
        path: 'default',
        comp: 'ExpenseList',
        icon: 'cisArrowLeft'
      },
      {
        type: 'link',
        show: true,
        name: 'Постоянные расходы',
        path: 'const',
        comp: 'ExpenseConstList',
        icon: 'cisArrowLeft'
      },
      {
        type: 'link',
        show: true,
        name: 'Премии и дивиденты',
        path: 'premium',
        comp: 'ExpensePremiumList',
        icon: 'cisArrowLeft'
      },
      {
        type: 'link',
        show: true,
        name: 'Типы расходов',
        path: 'type',
        comp: 'ExpenseTypeList',
        icon: 'cisList'
      }
    ]
  },
  {
    type: 'group',
    show: true,
    collapse: true,
    name: 'Транзакции',
    path: 'transaction',
    icon: 'cisTransfer',
    items: [
      {
        type: 'link',
        show: true,
        name: 'Доходы',
        path: 'income',
        comp: 'IncomeTransactionList',
        icon: 'cisArrowRight'
      },
      {
        type: 'link',
        show: true,
        name: 'Расходы',
        path: 'expense',
        comp: 'ExpenseTransactionList',
        icon: 'cisArrowLeft'
      },
      {
        type: 'link',
        show: true,
        name: 'Внутренние',
        path: 'inner',
        comp: 'InnerTransactionList',
        icon: 'cisLoop'
      }
    ]
  },
  // {
  //   type: 'link',
  //   show: true,
  //   name: 'Блокировка рейсов',
  //   path: 'blocked',
  //   comp: 'BlockedPeriodList',
  //   edit: 'BlockedPeriodEdit',
  //   icon: 'cisLockLocked'
  // },
  { type: 'divide' },
  {
    type: 'link',
    show: true,
    name: 'Авто доходы-расходы',
    path: 'autobalance',
    comp: 'AutoBalanceList',
    icon: 'cisBalanceScale'
  },

  // {
  //   type: 'link',
  //   show: true,
  //   name: 'Собственный транспорт',
  //   path: 'invest',
  //   comp: 'AutoInvestList',
  //   edit: 'AutoInvestEdit',
  //   icon: 'cisCommute'
  // },
  { type: 'divide' },
  {
    type: 'link',
    show: true,
    name: 'Базисы',
    path: 'basis',
    comp: 'BasisList',
    edit: 'BasisEdit',
    icon: 'cisFunctions'
  },
  {
    type: 'link',
    show: true,
    name: 'Премирование',
    path: 'bonus',
    comp: 'BonusList',
    edit: 'BonusEdit',
    icon: 'cisCash'
  },
  {
    type: 'custom-link',
    show: true,
    name: 'Расчет премий',
    path: 'period',
    comp: 'PeriodList',
    icon: 'cisCalculator',
    children: [
      {
        type: 'custom-link',
        name: 'Период',
        path: ':periodId',
        comp: 'PeriodItem',
        children: [
          {
            type: 'custom-link',
            name: 'Премия сотрудника',
            path: 'user/:userId',
            comp: 'UserBonusItem'
          }
        ]
      }
    ]
  },
  { type: 'divide' },
  {
    type: 'group',
    show: true,
    collapse: true,
    name: 'Отчеты',
    path: 'report',
    icon: 'cisDescription',
    items: [
      {
        type: 'link',
        show: true,
        name: 'Остатки',
        path: 'balance',
        comp: 'BalanceReportList',
        icon: 'cisDescription'
      },
      {
        type: 'link',
        show: false,
        name: 'Расходы детально',
        path: 'balance-exp',
        comp: 'BalanceExpenseList',
        icon: 'cisDescription'
      },
      {
        type: 'link',
        show: false,
        name: 'Доходы детально',
        path: 'balance-inc',
        comp: 'BalanceIncomeList',
        icon: 'cisDescription'
      },
      {
        type: 'link',
        show: true,
        name: 'Итоговый',
        path: 'final',
        comp: 'FinalReportList',
        icon: 'cisDescription'
      },
      {
        type: 'link',
        show: true,
        name: 'Проверочный',
        path: 'check',
        comp: 'CheckReportList',
        icon: 'cisDescription'
      }
    ]
  }
  // {
  //   type: 'group',
  //   show: true,
  //   collapse: true,
  //   name: 'Генератор документов',
  //   path: 'docmaker',
  //   icon: 'cisLayers',
  //   items: [
  //     {
  //       type: 'link',
  //       show: true,
  //       name: 'Шаблоны',
  //       path: 'tpl',
  //       comp: 'DocmakerTemplateList',
  //       edit: 'DocmakerTemplateEdit',
  //       icon: 'cisCode'
  //     },
  //     {
  //       type: 'link',
  //       show: true,
  //       name: 'Документы',
  //       path: 'doc',
  //       comp: 'DocmakerDocumentList',
  //       edit: 'DocmakerDocumentEdit',
  //       icon: 'cisCopy'
  //     }
  //   ]
  // }
]
