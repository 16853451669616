import { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'

const crumb = ref('')

const useCrumb = () => {
  const router = useRouter()
  const route = useRoute()

  let label = ''
  route.matched.forEach(item => {
    if (item.meta.label) {
      label += item.meta.label + ' / '
    }
  })
  crumb.value = label.slice(0, -3)

  router.beforeEach((to, from, next) => {
    let label = ''
    to.matched.forEach(item => {
      if (item.meta.label) {
        label += item.meta.label + ' / '
      }
    })
    crumb.value = label.slice(0, -3)
    next()
  })

  return {
    crumb
  }
}

export {
  useCrumb
}
