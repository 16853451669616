<template>
  <router-link :class="'btn btn-sm2 v-button btn-'+color" v-if="to" :to="to">
    <VIcon class="v-button-icon" :name="icon"/>
    {{ text }}
  </router-link>
  <button :class="'btn btn-sm2 v-button btn-'+color" v-else :disabled="disabled">
    <VIcon class="v-button-icon" :name="icon"/>
    {{ text }}
  </button>
</template>

<script>
export default {
  name: 'VButton',
  props: {
    icon: {
      type: String,
      required: true
    },
    to: {
      type: Object,
      required: false,
      default: null
    },
    text: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss">
$_primary: #009df1;
$_danger: #ff6c5f;
$_warning: #ffc168;
$_success: #01cd74;
$_dark: #535c69;
$_info: #007cc0;

.v-button {
  position: relative;
  padding-left: 34px !important;
  padding-right: 6px !important;
  color: white !important;

  .v-button-icon {
    position: absolute;
    width: 28px;
    height: 28px;
    padding: 4px;
    top: 0;
    left: 0;
    border-radius: 4px;
  }
}

.success {
  color: #fff !important;
  background-color: $_success !important;
  .v-button-icon {
    color: #fff !important;
    background-color: $_success !important;
  }
}

.primary {
  color: #fff !important;
  background-color: $_primary !important;
  .v-button-icon {
    color: #fff !important;
    background-color: $_primary !important;
  }
}

.warning {
  color: #fff !important;
  background-color: $_warning !important;
  .v-button-icon {
    color: #fff !important;
    background-color: $_warning !important;
  }
}

.dark {
  color: #fff !important;
  background-color: $_dark !important;
  .v-button-icon {
    color: #fff !important;
    background-color: $_dark !important;
  }
}

.info {
  color: #fff !important;
  background-color: $_info !important;
  .v-button-icon {
    color: #fff !important;
    background-color: $_info !important;
  }
}

.danger {
  color: #fff !important;
  background-color: $_danger !important;
  .v-button-icon {
    color: #fff !important;
    background-color: $_danger !important;
  }
}
</style>
