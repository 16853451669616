<template>
  <div class="list-input" v-show="ready">
    <div class="list-input-label">{{ label }} <span v-if="required" class="text-danger"><i class="fw-normal" :style="{ 'font-size': '12px' }">*</i></span></div>
    <div class="list-input-block">
      <div class="errors" v-if="errors.length">
        <div v-for="(e, i) in errors" :key="'error_' + i" v-html="e"></div>
        <div class="errors-close" @click="clearErrors()">+</div>
      </div>
      <table class="table table-bordered table-sm small mb-0">
        <thead>
        <tr>
          <th class="center">+</th>
          <th v-for="(col, key) in row" :key="key">

            <div v-if="col.type === 'Input'">
              <VInput v-if="col._type === 'number'" :label="col.label" :type="col._type" :placeholder="col.placeholder" v-model.number="newRow[key]"/>
              <VInput v-else :label="col.label" :type="col._type" :placeholder="col.placeholder" v-model="newRow[key]"/>
            </div>

            <div v-if="col.type === 'Checkbox'" class="mt-4">
              <VCheckbox :label="col.label" v-model="newRow[key]"/>
            </div>

            <div v-if="col.type === 'QuerySelect'">
              <label>
                {{ col.label }}
                <select v-model="newRow[key]">
                  <option :value="null" selected disabled>{{ col.placeholder }}</option>
                  <option v-for="(option, i) in col.options" :key="i" :value="option">{{ option.label }}</option>
                </select>
              </label>
            </div>

            <div v-if="col.type === 'Autocomplete'">
                <VAutocomplete
                    :label="col.label"
                    :placeholder="col.placeholder"
                    :from="col.from"
                    :in="col.in"
                    :out="col.out"
                    v-model="newRow[key]"
                    v-model:reset="resetAutocomplete"
                    return-label-value
                />
            </div>
          </th>
          <th class="center-btn">
            <button class="btn btn-success btn-sm3" @click="addRow()">
              <VIcon name="cilPlus"/>
            </button>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(r, i) in rows" :key="i">
          <th class="center">{{ i + 1 }}</th>
          <td v-for="(col, key) in r" :key="key">
            <span v-if="col === false">Нет</span>
            <span v-else-if="col === true">Да</span>
            <span v-else-if="col.label">{{ col.label }}</span>
            <span v-else-if="col.value">{{ col.value }}</span>
            <span v-else-if="col">{{ col }}</span>
          </td>
          <th class="trash" @click="deleteRow(i)">
            <VIcon name="cilTrash"/>
          </th>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import VIcon from '@/base/components/icon/VIcon'
import VInput from '@/base/components/elements/VInput'
import { useApolloClient } from '@vue/apollo-composable'
import VCheckbox from '@/base/components/elements/VCheckbox'
import VAutocomplete from '@/base/components/elements/VAutocomplete'

export default {
  name: 'VListInput',
  components: { VAutocomplete, VCheckbox, VInput, VIcon },
  props: {
    label: {
      type: String,
      required: true
    },
    columns: {
      type: Object,
      required: true
    },
    values: {
      type: Array,
      required: false,
      default: () => []
    },
    output: {
      type: Array,
      required: true
    },
    flatten: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props, { emit }) {
    const errors = ref([])
    const ready = ref(false)
    const rows = ref([])
    const row = ref({})
    const newRow = ref({})
    const nullRow = ref({})
    const resetAutocomplete = ref(false)
    const needSort = ref(false)

    function update () {
      const _rows = []
      if (props.flatten) {
        rows.value.forEach(item => {
          for (const key in item) {
            _rows.push(item[key].value || item[key])
          }
        })
      } else {
        rows.value.forEach(item => {
          const _row = {}
          for (const key in item) {
            _row[key] = item[key].value || item[key]
          }
          _rows.push(_row)
        })
      }
      emit('update:output', _rows)
    }

    function addRow () {
      errors.value = []
      for (const key in row.value) {
        if (!newRow.value[key]) {
          if (props.columns[key].validate) {
            if (props.columns[key].validate.required) {
              errors.value.push(props.columns[key].validate.required)
            }
          }
        } else {
          if (props.columns[key].validate) {
            if (props.columns[key].validate.unique) {
              rows.value.forEach(x => {
                if (x[key].value) {
                  if (x[key].value === newRow.value[key].value) {
                    errors.value.push(props.columns[key].validate.unique)
                  }
                } else {
                  if (x[key] === newRow.value[key]) {
                    errors.value.push(props.columns[key].validate.unique)
                  }
                }
              })
            }
          }
        }
      }
      if (errors.value.length || !newRow.value) return
      for (const key in newRow.value) {
        if (props.columns[key].type === 'NumberInput') {
          newRow.value[key] = newRow.value[key] ? newRow.value[key] : 0
        } else if (props.columns[key].type === 'Checkbox') {
          newRow.value[key] = newRow.value[key] !== null ? newRow.value[key] : false
        }
      }
      const _row = Object.assign({}, newRow.value)
      rows.value.push(_row)
      if (needSort.value) {
        rows.value = rows.value.sort((a, b) => a.sort - b.sort)
      }
      newRow.value = Object.assign({}, nullRow.value)
      resetAutocomplete.value = true
      update()
    }

    function deleteRow (index) {
      rows.value.splice(index, 1)
      update()
    }

    async function init () {
      for (const key in props.columns) {
        const col = props.columns[key]
        if (col.type === 'QuerySelect') await querySelect(col, key)
        if (col.type === 'Autocomplete') autocomplete(col, key)
        if (col.type === 'TextInput') createInput(col, key, 'text')
        if (col.type === 'TimeInput') createInput(col, key, 'time')
        if (col.type === 'NumberInput') createInput(col, key, 'number')
        if (col.type === 'Checkbox') {
          createCheckbox(col, key)
          nullRow.value[key] = false
        } else {
          nullRow.value[key] = null
        }
      }
      if (Object.keys(props.columns).includes('sort')) {
        needSort.value = true
      }
      newRow.value = Object.assign({}, nullRow.value)
      rows.value = props.values
      ready.value = true
      update()
    }

    const apollo = useApolloClient()

    async function querySelect (col, key) {
      await apollo.client.query({
        query: col.gqlQuery,
        fetchPolicy: 'no-cache',
        variables: col.gqlVars
      }).then(({ data }) => {
        const options = data[col.gqlKey].items.map(item => {
          return { label: item[col.labelField], value: item[col.valueField] }
        })
        row.value[key] = {
          label: col.label,
          placeholder: col.placeholder,
          type: col.type,
          options
        }
      }).catch(e => {
        console.error(e)
      })
    }

    function autocomplete (col, key) {
      row.value[key] = {
        label: col.label,
        placeholder: col.placeholder,
        type: col.type,
        from: col.from,
        in: col.in,
        out: col.out
      }
    }

    function createInput (col, key, _type) {
      row.value[key] = {
        label: col.label,
        placeholder: col.placeholder,
        type: 'Input',
        _type: _type
      }
    }

    function createCheckbox (col, key) {
      row.value[key] = {
        label: col.label,
        type: 'Checkbox'
      }
    }

    onMounted(() => {
      ready.value = false
      rows.value = []
      row.value = {}
      newRow.value = {}
      nullRow.value = {}
      resetAutocomplete.value = false
      init()
    })

    function clearErrors () {
      errors.value = []
    }

    return {
      errors,
      ready,
      row,
      rows,
      newRow,
      addRow,
      deleteRow,
      clearErrors,
      resetAutocomplete
    }
  }
}
</script>

<style lang="scss" scoped>
.list-input {

  .list-input-label {
    font-size: 12px;
    color: #4f5d73;
    font-weight: 700;
    display: block;
    margin: 0;
    padding: 0;
  }

  .list-input-block {

  }

  .errors {
    display: block;
    position: relative;
    background-color: #e55353;
    color: #fff;
    font-weight: bold;
    padding: 1rem;
    padding-left: 4rem !important;
    line-height: 1.2;
    font-size: 12px;

    .errors-close {
      font-size: 30px;
      border-radius: 20px;
      position: absolute;
      z-index: 1000;
      top: 6px;
      left: 28px;
      cursor: pointer;
      transform: rotate(45deg);
      color: #fff;
      transition: all 280ms ease-in-out;

      &:hover {
        transform: rotate(45deg) scale(1.1);
      }
    }
  }

  label {
    font-size: 12px;
    color: #4f5d73;
    font-weight: 700;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  select {
    display: block;
    width: 100%;
    height: 28px;
    margin: 0;
    padding: 0 10px;
    border: 1px solid #d8dbe0;

    &:focus {
      outline: none;
      border-color: #64d5ca;
    }
  }

  select:not(-internal-list-box) {
    background-color: white;
    border: 1px solid #d8dbe0;
  }

}

.table {
  white-space: normal;
}

.center {
  text-align: center;
  width: 5%;
}

.center-btn {
  text-align: center;
  width: 5%;
  vertical-align: bottom;
}

.trash {
  text-align: center;
  color: #e55353;
  cursor: pointer;
}
</style>
