<template>
    <div :class="modalClasses" tabindex="-1" role="dialog" :style="{ 'z-index': 9999 }">
      <div :class="dialogClasses" role="document">
        <div :class="contentClasses">
          <header v-if="confirm" class="modal-header" :class="{'bg-danger': confirm.color === 'danger'}">
              <h5 class="modal-title">{{ title }}</h5>
          </header>
          <header v-else class="modal-header">
            <h5 class="modal-title">{{ title }}</h5>
          </header>
          <VError v-model="errors"/>
          <div class="modal-body">
            <slot></slot>
          </div>
          <footer class="modal-footer" v-if="!hideButtons">
            <slot name="footer">
              <button
                  type="button"
                  class="ui-btn ui-btn-light"
                  @click="close"
              >
                <span v-if="cancel">{{ cancel.text }}</span>
                <span v-else>Отмена</span>
              </button>
              <button
                  v-if="action"
                  type="button"
                  :class="'ui-btn ui-btn-' + action.color + ' ml-1'"
                  @click="$emit('action')"
              >
                {{ action.text }}
              </button>
              <button
                  v-if="confirm"
                  type="button"
                  :class="'ui-btn ui-btn-' + confirm.color + ' ml-1'"
                  @click="$emit('confirm')"
                  :disabled="disabled"
              >
                {{ confirm.text }}
              </button>
            </slot>
          </footer>
        </div>
      </div>
    </div>
    <div
        v-if="visible || isTransitioning"
        :class="backdropClasses"
    >
    </div>
</template>

<script>
import { ref, onMounted, watchEffect } from 'vue'
import { useModals } from '@/base/use/modal'
import VError from '@/base/components/error/VError'

export default {
  name: 'Modal',
  components: { VError },
  props: {
    title: {
      type: String,
      required: true
    },
    cancel: {
      type: Object,
      required: false,
      default: () => null
    },
    action: {
      type: Object,
      required: false,
      default: () => null
    },
    confirm: {
      type: Object,
      required: false,
      default: () => null
    },
    size: {
      type: String,
      validator: val => ['', 'sm', 'lg', 'xl'].includes(val)
    },
    centered: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    hideButtons: {
      type: Boolean,
      required: false,
      default: false
    },
    color: String,
    borderColor: String
  },
  emits: ['action', 'confirm'],
  setup () {
    const { needHide, hide, destroy, errors } = useModals()

    const visible = ref(false)
    const isTransitioning = ref(false)
    const timeout = ref(null)

    const toggle = (newVal) => {
      setTimeout(() => {
        visible.value = newVal
      }, 0)

      isTransitioning.value = true
      clearTimeout(timeout.value)
      timeout.value = setTimeout(() => {
        isTransitioning.value = false
        if (newVal === false) {
          destroy()
        }
      }, 150)
    }

    onMounted(() => {
      toggle(true)
    })

    watchEffect(() => {
      if (needHide.value) toggle(false)
    })

    const close = () => {
      hide()
    }

    return {
      close,
      visible,
      isTransitioning,
      timeout,
      errors
    }
  },
  computed: {
    backdropClasses () {
      return {
        'modal-backdrop': true,
        fade: true,
        show: this.visible
      }
    },
    modalClasses () {
      return [
        'modal overflow-auto',
        {
          fade: !this.centered,
          scale: this.centered,
          show: this.visible,
          'd-block': this.visible || this.isTransitioning,
          [`modal-${this.color}`]: this.color
        }
      ]
    },
    dialogClasses () {
      return [
        'modal-dialog',
        {
          'modal-dialog-centered': this.centered,
          [`modal-${this.size}`]: this.size
        }
      ]
    },
    contentClasses () {
      return [
        'modal-content',
        {
          [`border-${this.borderColor}`]: this.borderColor
        }
      ]
    }
  }
}
</script>
