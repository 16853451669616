<template>
  <table :class="tableClasses" v-if="table">
    <thead v-if="table.head instanceof Array" class="thead-light">
    <tr>
      <th v-for="(col, i) in table.head"
          :class="{nobr: col.nobr, sm: col.sm, curr: col.curr}"
          :style="{textAlign: col.align}"
          :key="'col'+i"
      >
        {{ col.name }}
        <span v-if="col.tooltip" v-tooltip:bottom.tooltip="col.tooltip.text">
          <VIcon name="cisInfoCircle" class="icon-info"/>
        </span>
        <span v-if="col.sort">
          <VIcon
              name="cisSortAscending"
              class="icon-sort"
              :class="{ 'text-success': col.sort.field === sort && order === 'asc' }"
              @click="$emit('sorting', { field: col.sort.field, dir: 'asc' })"
          />
          <VIcon
              name="cisSortDescending"
              class="icon-sort"
              :class="{ 'text-success': col.sort.field === sort && order === 'desc' }"
              @click="$emit('sorting', { field: col.sort.field, dir: 'desc' })"
          />
        </span>
      </th>
    </tr>
    </thead>
    <thead v-else v-html="table.head"/>
    <tbody>
    <tr v-for="(cols, num) in table.rows" :key="num">
      <td
          v-for="(col, i) in cols"
          :key="num + '-' + i"
          class="cell"
          :class="{nobr: col.nobr}"
          :style="{textAlign: col.align}">

        <template v-if="col.type === 'num'">
          <i v-html="num+((table.page-1)*table.size)+1" :style="{color: 'gray'}"/>
        </template>

        <template v-if="col.type === 'link'">
          <router-link :to="col.to" :style="{ textAlign: col.align }">
            <VIcon v-if="col.icon" :name="col.icon" :style="{color: col.color}"/>
            <span v-if="col.val" :class="{ bold: col.bold, underline: col.underline }" v-html="col.val"/>
          </router-link>
        </template>

        <template v-if="col.type === 'external-link'">
          <a :href="col.href" :style="{textAlign: col.align}" target="_blank">
            <VIcon v-if="col.icon" :name="col.icon" :style="{color: col.color}"/>
            <span v-if="col.val" v-html="col.val"/><br>
          </a>
        </template>

        <template v-if="col.type === 'func'">
          <a v-if="!col.disabled" href="#" :style="{textAlign: col.align}" @click.prevent="col.func($event, col.arg)">
            <VIcon v-if="col.icon" :name="col.icon" :style="{color: col.color}"/>
            <span v-if="col.trim && col.val"
                  v-html="col.val.length > col.trim ? col.val.slice(0, col.trim) + ' ...' : col.val"
                  :style="{textAlign: col.align, color: col.color}"/>
            <span v-else v-html="col.val" :style="{textAlign: col.align, color: col.color}"/>
          </a>
        </template>

        <template v-if="col.type === 'text'">
          <VIcon v-if="col.icon" :name="col.icon" :style="{textAlign: col.align, color: col.color}"/>
          <span v-if="col.trim && col.val" v-tooltip="col.val" v-html="col.val.length > col.trim ? col.val.slice(0, col.trim) + ' ...' : col.val"
                :class="{ bold: col.bold, muted: col.muted }" :style="{textAlign: col.align, color: col.color}"/>
          <span v-else v-html="col.val" :class="{ bold: col.bold, muted: col.muted }" :style="{textAlign: col.align, color: col.color}"/>
        </template>

        <template v-if="col.type === 'text-tooltip'">
          <span v-if="col.tooltip.dir = 'right'" v-tooltip:right.tooltip="col.tooltip.text" v-html="col.val" :class="{ bold: col.bold }" :style="{textAlign: col.align, color: col.color}"/>
        </template>

        <template v-if="col.type === 'not-setup'">
          <span class="not-setup">не установлено</span>
        </template>

        <template v-if="col.type === 'no-data'">
          <span class="no-data">нет</span>
        </template>

        <template v-if="col.type === 'working'">
          <span class="working"><img src="../../../assets/status-work.gif"/> в обработке</span>
        </template>

        <template v-if="col.type === 'list'">
          <div v-for="(item, j) in col.list" :key="j" :style="{textAlign: col.align, color: col.color}">
            <template v-if="item.type === 'text'">
                  <span v-if="item.trim && item.val"
                        v-html="item.val.length > item.trim ? item.val.slice(0, item.trim) + ' ...' : item.val"
                        :style="{textAlign: item.align, color: item.color}"></span>
              <span v-else v-html="item.val" :style="{textAlign: col.align, color: col.color, 'font-size': '13px' }"></span><br>
            </template>
            <template v-if="item.type === 'external-link'">
              <a :href="item.href" :style="{textAlign: item.align}" target="_blank">
                <VIcon v-if="item.icon" :name="item.icon" :style="{color: item.color}"/>
                <span v-if="item.val" v-html="item.val"></span>
              </a><br>
            </template>
            <template v-if="item.type === 'func'">
              <a v-if="!item.disabled" href="#" :style="{textAlign: item.align}" @click.prevent="item.func($event, item.arg)">
                <VIcon v-if="item.icon" :name="item.icon" :style="{color: item.color}"/>
              </a><br>
            </template>
            <template v-if="item.type === 'date'">
              <span v-html="getDate(item)" :style="{textAlign: item.align}"/><br>
            </template>
          </div>
        </template>

        <template v-if="col.type === 'date'">
          <span v-html="getDate(col)" :style="{textAlign: col.align}"/>
        </template>

        <template v-if="col.type === 'status'">
          <span v-html="getPaidStatus(col)" :style="{textAlign: col.align}"/>
        </template>

        <template v-if="col.type === 'rub'">
          <span v-if="!col.colored" v-html="rub(col.val, col.nozero)"/>
          <span v-else :style="{color: colored(col.val)}" v-html="rub(col.val, col.nozero)"/>
        </template>

        <template v-if="col.type === 'round2'">
          <span v-if="!col.colored" v-html="round2(col.val, col.nozero, col.postfix)"/>
          <span v-else :style="{color: colored(col.val)}" v-html="round2(col.val, col.nozero, col.postfix)"/>
        </template>

        <template v-if="col.type === 'delete'">
          <a v-if="!col.disabled" href="#" :style="{textAlign: col.align, color: col.color ? col.color : 'red'}"
             @click.prevent="showDeleteModal(col.key, col.id, col.text)">
            <VIcon v-if="col.icon" :name="col.icon"/>
            <VIcon v-else name="cisTrashX"/>
          </a>
        </template>

      </td>
    </tr>
    </tbody>
  </table>

  <div v-if="table">
    <div class="empty-results" v-if="table.rows.length < 1">
      Ничего не найдено
    </div>
  </div>

  <VPagination
      v-if="table.pages > 1"
      :active-page="table.page"
      :pages="table.pages"
      @paginate="pagination"
  >
  </VPagination>
</template>

<script>
import moment from 'moment'
import { rub as toRub, round2 as toRound2 } from '../../utils'
import VIcon from '../icon/VIcon'
import VPagination from './VPagination'
import DeleteModal from '../modal/DeleteModal'
import { ref, computed } from 'vue'
import { useModals } from '@/base/use/modal'

export default {
  name: 'VTable',
  components: {
    VIcon,
    VPagination
  },
  props: {
    tableData: {
      type: Object,
      required: true
    },
    tableHeader: {
      type: [Array, String],
      required: false,
      default: null
    },
    buildRow: {
      type: Function,
      required: false,
      default: null
    },
    buildTable: {
      type: Function,
      required: false,
      default: null
    },
    paginate: {
      type: Function,
      required: false,
      default: () => {
      }
    },
    tableBordered: {
      type: Boolean,
      required: false,
      default: true
    },
    tableTextCenter: {
      type: Boolean,
      required: false,
      default: false
    },
    sort: {
      type: String,
      required: false,
      default: null
    },
    order: {
      type: String,
      required: false,
      default: null
    }
  },
  emits: ['sorting'],
  setup (props) {
    const modal = useModals({ name: 'DeleteModal', comp: DeleteModal })

    const table = computed(() => {
      if (props.tableHeader && props.buildRow) {
        const rows = []
        props.tableData.items.forEach(item => {
          rows.push(props.buildRow(item))
        })
        return {
          page: props.tableData.page,
          size: props.tableData.size,
          count: props.tableData.count,
          pages: props.tableData.pages,
          head: props.tableHeader,
          rows
        }
      } else if (props.buildTable) {
        const { head, rows } = props.buildTable(props.tableData)
        return {
          page: props.tableData.page,
          size: props.tableData.size,
          count: props.tableData.count,
          pages: props.tableData.pages,
          head,
          rows
        }
      }
      return {}
    })

    const tableClasses = computed(() => ({
      table: true,
      'table-hover': true,
      'table-sm': true,
      'table-bordered': props.tableBordered,
      'text-center': props.tableTextCenter
    }))

    const pagination = page => {
      props.paginate(page)
    }

    function rub (val, nozero) {
      if (!val && nozero) return null
      return toRub(val)
    }

    function round2 (val, nozero, postfix) {
      if (!val && nozero) return null
      return postfix ? toRound2(val) + ' ' + postfix : toRound2(val)
    }

    function colored (val) {
      if (val === 0) return 'gray'
      return val > 0 ? 'green' : 'red'
    }

    function getPaidStatus (status) {
      if (status.paidSum === 0) return '<span class="badge bg-danger">не оплачено</span>'
      if (status.paidSum < status.sum) return '<span class="badge bg-warning">частично</span>'
      if (status.paidSum === status.sum) return '<span class="badge bg-success">оплачено</span>'
    }

    function getDate (col) {
      return (col && col.val && col.format) ? moment(col.val).format(col.format) : '-'
    }

    function showDeleteModal (key, id, text) {
      modal.show('DeleteModal', { key, id, text }, pagination)
    }

    const headerTooltipName = ref(null)

    return {
      table,
      tableClasses,
      pagination,
      rub,
      round2,
      colored,
      getPaidStatus,
      getDate,
      showDeleteModal,
      headerTooltipName
    }
  }
}
</script>

<style lang="scss" scoped>
.empty-results {
  padding: 15px;
  font-style: italic;
  text-align: center;
}

.nobr {
  white-space: nowrap;
}

.sm {
  width: 25px;
  padding: 0 25px;
}

.bold {
  font-weight: 700;
}

.muted {
  font-size: 12px;
  color: #3d4852;
}

.not-setup {
  color: #e55353;
  font-style: italic;
  font-size: 12px;
  opacity: .75;
}

.working {
  font-style: italic;
  font-size: 12px;
  opacity: .75;
}

.no-data {
  color: gray;
  font-style: italic;
  font-size: 12px;
  opacity: .75;
}

.curr {
  background-color: #bbed21;
}

.icon-info {
  width: 16px;
}

.icon-sort {
  width: 16px;
  &:hover {
    cursor: pointer;
    color: #009df1;
  }
}

.underline {
  &:hover {
     text-decoration: underline;
   }
}
</style>
