<template>
  <div class="header">
    <div class="sidebar-toggle" v-if="sidebar" @click="sidebarToggle()" v-tooltip:left="'Скрыть меню'">
      <VIcon name="cisCaretLeft" color="orange"/>
    </div>
    <div class="sidebar-toggle" v-else @click="sidebarToggle()" v-tooltip:right="'Показать меню'">
      <VIcon name="cisCaretRight" color="orange"/>
    </div>
    <div class="header-menu" v-if="sidebar">
      <div
          v-for="(item, i) in nav"
          :key="'header-menu' + i"
          class="header-menu-item"
          :class="{ active: checkActive(item.path) }"
          @click="setSection(item)"
      >
        <VIcon v-if="item.icon" :name="item.icon" :title="item.name"/>
        <span v-else>{{ item.name }}</span>
      </div>
    </div>
    <div class="header-menu-line" v-else-if="menu && menu.items">
      <template v-for="(item, i) in menu.items">
        <router-link
            v-if="item.type === 'link' || item.type === 'menu'"
            :key="'header-menu-line' + i"
            class="header-menu-line-item"
            :class="{ active: checkActive(item.path) }"
            :to="{ name: item.comp }"
        >
          {{ item.name }}
        </router-link>
      </template>
    </div>
    <div class="user-pr">
      <div class="user-name">
        {{ getUserName() }}<br>
        <div class="user-badge" v-html="getRoleBadge()"></div>
      </div>
      <div class="user-photo" @click="visibleProfile = true" v-tooltip:left="'Профиль'">
        <img src="../../assets/empty-user.png" alt="user" title="Профиль"/>
      </div>
    </div>
  </div>
  <div class="breadcrumbs">
    <b>{{ crumb }}</b>
  </div>
  <Errors/>
  <ProfilePanel v-model="visibleProfile"/>
</template>

<script>
import map from '../setup/map'
import VIcon from '@/base/components/icon/VIcon'
import { onMounted, computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useMenu } from '@/base/use/menu'
import { useCrumb } from '@/base/use/crumb'
import { useUser } from '@/base/use/user'
import { useModals } from '@/base/use/modal'
import Profile from '@/base/layout/Profile'
import Errors from '@/base/layout/Errors'
import ProfilePanel from '@/base/components/panels/ProfilePanel'

export default {
  name: 'Header',
  components: { Errors, VIcon, ProfilePanel },

  setup () {
    const modal = useModals({ name: 'Profile', comp: Profile })

    const route = useRoute()
    const { menu, setSection, sidebar, sidebarToggle } = useMenu()
    const { crumb } = useCrumb()
    const { getUserName, getRoleBadge, checkAccess } = useUser()

    const nav = computed(() => {
      const _nav = []
      map.forEach(item => {
        if (checkAccess(item.meta)) _nav.push(item)
      })
      return _nav
    })

    onMounted(() => {
      let section = {}
      if (route.path.match('/operating')) {
        section = nav.value.filter(x => {
          return x.path === 'operating'
        })
      } else if (route.path.match('/dispatcher')) {
        section = nav.value.filter(x => {
          return x.path === 'dispatcher'
        })
      } else if (route.path.match('/accounting')) {
        section = nav.value.filter(x => {
          return x.path === 'accounting'
        })
      } else if (route.path.match('/transport')) {
        section = nav.value.filter(x => {
          return x.path === 'transport'
        })
      } else if (route.path.match('/inventory')) {
        section = nav.value.filter(x => {
          return x.path === 'inventory'
        })
      } else if (route.path.match('/administrating')) {
        section = nav.value.filter(x => {
          return x.path === 'administrating'
        })
      }

      setSection(section[0])
    })

    function checkActive (path) {
      return route.path.match(path)
    }

    const visibleProfile = ref(false)

    return {
      nav,
      menu,
      crumb,
      setSection,
      sidebar,
      sidebarToggle,
      getUserName,
      getRoleBadge,
      modal,
      checkActive,
      visibleProfile
    }
  }
}
</script>

<style lang="scss">
.new-notify {
  animation: blink 2s linear infinite;
}
@keyframes blink {
  0% { background-color: transparent; color: white; }
  49% { background-color: transparent; color: white; }
  50% { background-color: greenyellow; color: #535c69; }
  100% { background-color: greenyellow; color: #535c69; }
}
</style>
