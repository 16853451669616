const SelfCompanyList = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/selfcompany/SelfCompanyList')
const SelfCompanyAccountList = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/selfcompany/account/SelfCompanyAccountList')
const SelfCompanyBankList = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/selfcompany/bank/SelfCompanyBankList')
const SupplierList = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/supplier/SupplierList')
const SupplierCategoryList = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/supplier/suppcat/SupplierCategoryList')
const CompanyList = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/company/CompanyList')
const IncomeList = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/income/default/IncomeList')
const Deductions = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/expense/Deductions')
const ExpenseList = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/expense/default/ExpenseList')
const ExpenseConstList = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/expense/const/ExpenseConstList')
const ExpensePremiumList = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/expense/premium/ExpensePremiumList')
const ExpenseTypeList = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/expense/type/ExpenseTypeList')
const IncomeTransactionList = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/transaction/income/IncomeTransactionList')
const ExpenseTransactionList = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/transaction/expense/ExpenseTransactionList')
const InnerTransactionList = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/transaction/inner/InnerTransactionList')
const AutoBalanceList = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/autobalance/AutoBalanceList')
const AutoInvestList = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/invest/AutoInvestList')
const SelfAutoIncomeList = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/invest/income/SelfAutoIncomeList')
const SelfAutoExpenseList = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/invest/expense/SelfAutoExpenseList')
const AutoInvestUserList = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/invest/user/AutoInvestUserList')
const AutoInvestReportList = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/invest/report/AutoInvestReportList')
const AutoDataReport = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/invest/autodata/AutoDataReport')
const UserDataReport = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/invest/userdata/UserDataReport')
const BasisList = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/basis/BasisList')
const BonusList = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/bonus/BonusList')
const PeriodList = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/period/PeriodList')
const PeriodItem = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/period/PeriodItem')
const UserBonusItem = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/period/UserBonusItem')
const BalanceReportList = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/report/balance/BalanceReportList')
const BalanceExpenseList = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/report/balance-exp/BalanceExpenseList')
const BalanceIncomeList = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/report/balance-inc/BalanceIncomeList')
const FinalReportList = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/report/final/FinalReportList')
const CheckReportList = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/report/check/CheckReportList')
const DocmakerTemplateList = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/docmaker/tpl/DocmakerTemplateList')
const DocmakerDocumentList = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/docmaker/doc/DocmakerDocumentList')
const BlockedPeriodList = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/blocked/BlockedPeriodList')
const AutoExpensesBatch = () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/invest/AutoExpensesBatch')
export default [
  // {
  //   path: 'selfcompany',
  //   meta: { roles: ['root', 'boss'], groups: ['accounting'], label: 'Организации' },
  //   component: { template: '<router-view></router-view>' },
  //   children: [
  //     {
  //       path: ':param?',
  //       meta: { roles: ['root', 'boss'], groups: ['accounting'] },
  //       name: 'SelfCompanyList',
  //       component: SelfCompanyList
  //     },
  //     {
  //       path: 'account/:param?',
  //       meta: { roles: ['root', 'boss'], groups: ['accounting'], label: 'Счета' },
  //       name: 'SelfCompanyAccountList',
  //       component: SelfCompanyAccountList
  //     },
  //     {
  //       path: 'bank/:param?',
  //       meta: { roles: ['root', 'boss'], groups: ['accounting'], label: 'Банки' },
  //       name: 'SelfCompanyBankList',
  //       component: SelfCompanyBankList
  //     }
  //   ]
  // },
  // {
  //   path: 'supplier',
  //   meta: { roles: ['root', 'boss'], groups: ['accounting'], label: 'Поставщики' },
  //   component: { template: '<router-view></router-view>' },
  //   children: [
  //     {
  //       path: ':param?',
  //       meta: { roles: ['root', 'boss'], groups: ['accounting'] },
  //       name: 'SupplierList',
  //       component: SupplierList
  //     },
  //     {
  //       path: 'suppcat/:param?',
  //       meta: { roles: ['root', 'boss'], groups: ['accounting'], label: 'Категории' },
  //       name: 'SupplierCategoryList',
  //       component: SupplierCategoryList
  //     }
  //   ]
  // },
  // {
  //   path: 'client',
  //   meta: { roles: ['root', 'boss'], groups: ['accounting'], label: 'Клиенты' },
  //   name: 'CompanyList',
  //   component: CompanyList,
  //   children: []
  // },
  {
    path: 'income',
    meta: { roles: ['root', 'boss', 'financier'], label: 'Доходы' },
    component: { template: '<router-view></router-view>' },
    children: [
      {
        path: 'default',
        meta: { roles: ['root', 'boss', 'financier'], label: 'Общая категория' },
        name: 'IncomeList',
        component: IncomeList
      }
    ]
  },
  {
    path: 'expense',
    meta: { roles: ['root', 'boss', 'financier'], label: 'Расходы' },
    component: { template: '<router-view></router-view>' },
    children: [
      {
        path: 'deductions',
        meta: { roles: ['root', 'boss', 'financier'], label: 'Вычеты рейсов' },
        name: 'Deductions',
        component: Deductions
      },
      {
        path: 'default',
        meta: { roles: ['root', 'boss', 'financier'], label: 'Переменные расходы' },
        name: 'ExpenseList',
        component: ExpenseList
      },
      {
        path: 'const',
        meta: { roles: ['root', 'boss', 'financier'], label: 'Постоянные расходы' },
        name: 'ExpenseConstList',
        component: ExpenseConstList
      },
      {
        path: 'premium',
        meta: { roles: ['root', 'boss', 'financier'], label: 'Премии и дивиденты' },
        name: 'ExpensePremiumList',
        component: ExpensePremiumList
      },
      {
        path: 'type',
        meta: { roles: ['root', 'boss', 'financier'], label: 'Типы расходов' },
        name: 'ExpenseTypeList',
        component: ExpenseTypeList
      }
    ]
  },
  {
    path: 'transaction',
    meta: { roles: ['root', 'boss', 'financier'], label: 'Транзакции' },
    component: { template: '<router-view></router-view>' },
    children: [
      {
        path: 'income',
        meta: { roles: ['root', 'boss', 'financier'], label: 'Доходы' },
        name: 'IncomeTransactionList',
        component: IncomeTransactionList
      },
      {
        path: 'expense',
        meta: { roles: ['root', 'boss', 'financier'], label: 'Расходы' },
        name: 'ExpenseTransactionList',
        component: ExpenseTransactionList
      },
      {
        path: 'inner',
        meta: { roles: ['root', 'boss', 'financier'], label: 'Внутренние' },
        name: 'InnerTransactionList',
        component: InnerTransactionList
      }
    ]
  },
  // {
  //   path: 'blocked',
  //   meta: { roles: ['root', 'boss', 'financier'], label: 'Блокировка рейсов' },
  //   component: { template: '<router-view></router-view>' },
  //   children: [
  //     {
  //       path: ':param?',
  //       meta: { roles: ['root', 'boss', 'financier'] },
  //       name: 'BlockedPeriodList',
  //       component: BlockedPeriodList
  //     }
  //   ]
  // },
  {
    path: 'autobalance',
    meta: { roles: ['root', 'boss'], groups: ['accounting'], label: 'Авто доходы-расходы' },
    name: 'AutoBalanceList',
    component: AutoBalanceList,
    children: []
  },
  // {
  //   path: 'invest',
  //   meta: { roles: ['root', 'boss'], groups: ['accounting'], label: 'Собственный транспорт' },
  //   component: { template: '<router-view></router-view>' },
  //   children: [
  //     {
  //       path: ':param?',
  //       meta: { roles: ['root', 'boss'], groups: ['accounting'] },
  //       name: 'AutoInvestList',
  //       component: AutoInvestList
  //     },
  //     {
  //       path: 'autodata',
  //       meta: { roles: ['root', 'boss'], groups: ['accounting'], label: 'Сводный отчет' },
  //       name: 'AutoDataReport',
  //       component: AutoDataReport
  //     },
  //     {
  //       path: 'userdata',
  //       meta: { roles: ['root', 'boss'], groups: ['accounting'], label: 'Выплаты по месяцам' },
  //       name: 'UserDataReport',
  //       component: UserDataReport
  //     },
  //     {
  //       path: ':auto/income',
  //       props: true,
  //       meta: { roles: ['root', 'boss'], groups: ['accounting'], label: 'Доходы' },
  //       name: 'SelfAutoIncomeList',
  //       component: SelfAutoIncomeList
  //     },
  //     {
  //       path: ':auto/expense',
  //       props: true,
  //       meta: { roles: ['root', 'boss'], groups: ['accounting'], label: 'Расходы' },
  //       name: 'SelfAutoExpenseList',
  //       component: SelfAutoExpenseList
  //     },
  //     {
  //       path: ':auto/user',
  //       props: true,
  //       meta: { roles: ['root', 'boss'], groups: ['accounting'], label: 'Участники' },
  //       name: 'AutoInvestUserList',
  //       component: AutoInvestUserList
  //     },
  //     {
  //       path: ':auto/report',
  //       props: true,
  //       meta: { roles: ['root', 'boss'], groups: ['accounting'], label: 'Отчеты' },
  //       name: 'AutoInvestReportList',
  //       component: AutoInvestReportList
  //     },
  //     {
  //       path: 'expenses-batch',
  //       meta: { roles: ['root', 'boss'], groups: ['accounting'], label: 'Добавление расходов' },
  //       name: 'AutoExpensesBatch',
  //       component: AutoExpensesBatch
  //     }
  //   ]
  // },
  {
    path: 'basis/:param?',
    meta: { roles: ['root', 'boss', 'financier'], label: 'Базисы' },
    name: 'BasisList',
    component: BasisList,
    children: []
  },
  {
    path: 'bonus/:param?',
    meta: { roles: ['root', 'boss', 'financier'], label: 'Премирование' },
    name: 'BonusList',
    component: BonusList,
    children: []
  },
  {
    path: 'period',
    meta: { roles: ['root', 'boss', 'financier'], label: 'Расчет премий' },
    component: { template: '<router-view></router-view>' },
    children: [
      {
        path: '',
        meta: { roles: ['root', 'boss', 'financier'] },
        name: 'PeriodList',
        component: PeriodList
      },
      {
        path: ':periodId',
        meta: { roles: ['root', 'boss', 'financier'], label: 'Период' },
        component: { template: '<router-view></router-view>' },
        children: [
          {
            path: '',
            meta: { roles: ['root', 'boss', 'financier'] },
            name: 'PeriodItem',
            component: PeriodItem
          },
          {
            path: 'user/:userId',
            meta: { roles: ['root', 'boss', 'financier'], label: 'Премия сотрудника' },
            component: { template: '<router-view></router-view>' },
            children: [
              {
                path: '',
                meta: { roles: ['root', 'boss', 'financier'] },
                name: 'UserBonusItem',
                component: UserBonusItem
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'report',
    meta: { roles: ['root', 'boss', 'financier'], label: 'Отчеты' },
    component: { template: '<router-view></router-view>' },
    children: [
      {
        path: 'balance',
        meta: { roles: ['root', 'boss', 'financier'], label: 'Остатки' },
        name: 'BalanceReportList',
        component: BalanceReportList
      },
      {
        path: 'balance-exp',
        meta: { roles: ['root', 'boss', 'financier'], label: 'Расходы детально' },
        name: 'BalanceExpenseList',
        component: BalanceExpenseList
      },
      {
        path: 'balance-inc',
        meta: { roles: ['root', 'boss', 'financier'], label: 'Доходы детально' },
        name: 'BalanceIncomeList',
        component: BalanceIncomeList
      },
      {
        path: 'final',
        meta: { roles: ['root', 'boss', 'financier'], label: 'Итоговый' },
        name: 'FinalReportList',
        component: FinalReportList
      },
      {
        path: 'check',
        meta: { roles: ['root', 'boss', 'financier'], label: 'Проверочный' },
        name: 'CheckReportList',
        component: CheckReportList
      }
    ]
  }
  // {
  //   path: 'docmaker',
  //   meta: { roles: ['root', 'boss'], groups: ['accounting'], label: 'Генератор документов' },
  //   component: { template: '<router-view></router-view>' },
  //   children: [
  //     {
  //       path: 'tpl/:param?',
  //       meta: { roles: ['root', 'boss'], groups: ['accounting'], label: 'Шаблоны' },
  //       name: 'DocmakerTemplateList',
  //       component: DocmakerTemplateList
  //     },
  //     {
  //       path: 'doc/:param?',
  //       meta: { roles: ['root', 'boss'], groups: ['accounting'], label: 'Документы' },
  //       name: 'DocmakerDocumentList',
  //       component: DocmakerDocumentList
  //     }
  //   ]
  // }
]
