import gql from 'graphql-tag'

export const CURRENT_USER = gql`
query {
  CurrentUser {
    id
    code
    name
    email
    phone
    phones
    botId
    externalId
    roles {
      id
      code
      name
    }
    groups {
      id
      code
      name
    }
    notifies {
      id
      initiator {
        name
      }
      type
      title
      text
      link
      active
      date
      modelName
      modelCode
      status
    }
  }
}
`

export const USER = gql`
query ($code: String) {
  User (code: $code) {
    items {
      id
      code
      name
      email
      phone
      phones
      externalId
      type
      botId
      appId
      roles {
        id,
        code
        name
      }
      groups {
        id
        code
        name
      }
      supplier {
        id
        code
        name
      }
    }
  }
}
`

export const USER_LIST = gql`
query ($code: String, $page: Int, $size: Int, $sort: String, $order: String, $hasBot: String, $isSupplier: String) {
  User (code: $code, page: $page, size: $size, sort: $sort, order: $order, hasBot: $hasBot, isSupplier: $isSupplier) {
    page
    size
    count
    pages
    items {
      id
      code
      name
      email
      phone
      phones
      externalId
      type
      botId
      appId
      roles {
        id
        code
        name
      }
      groups {
        id
        code
        name
      }
      supplier {
        id
        code
        name
        phone
      }
    }
  }
}
`

export const USER_PUSH = gql`
mutation ($user: UserPushInput!) {
  UserPush(user: $user) {
    id
    code
    success
  }
}
`
