<template>
  <div :class="wrapClass" v-show="result">
    <div v-if="edit && param">
      <component
          :param="param"
          :is="edit.component"
          @after-push="update"
      />
    </div>
    <slot name="controls"/>
    <slot name="help"/>
    <div v-if="tableFilter">
      <div class="line"></div>
      <VFilter
          :settings="tableFilter"
          @filtering="filtering"
      />
    </div>
    <slot v-if="result" name="result"/>
    <div v-if="result">
      <div class="line"></div>
      <VTable
          :table-data="result"
          :table-header="tableHeader"
          :build-row="buildRow"
          :build-table="buildTable"
          :table-bordered="tableBordered"
          :table-text-center="tableTextCenter"
          :paginate="paginate"
          :sort="sort"
          :order="order"
          @sorting="$emit('sorting', $event)"
      />
    </div>
  </div>
  <VLoader v-if="loading"/>
</template>

<script>
import { ref, watch, watchEffect } from 'vue'
import VTable from '../table/VTable'
import VFilter from '../filter/VFilter'
import VLoader from '../loader/VLoader'
import { useGqlQuery } from '../../use/gql'
import { useRoute } from 'vue-router'

export default {
  name: 'ListContainer',
  components: {
    VTable,
    VLoader,
    VFilter
  },
  props: {
    wrapClass: {
      type: String,
      required: false,
      default: 'page'
    },
    edit: {
      type: Object,
      required: false,
      default: null
    },
    gqlKey: {
      type: String,
      required: true
    },
    gqlQuery: {
      type: Object,
      required: true
    },
    gqlVars: {
      type: Object,
      required: false,
      default: null
    },
    fetchPolicy: {
      type: String,
      required: false,
      default: 'no-cache'
    },
    page: {
      type: Number,
      required: false,
      default: 1
    },
    size: {
      type: Number,
      required: false,
      default: 50
    },
    sort: {
      type: String,
      required: false,
      default: 'id'
    },
    order: {
      type: String,
      required: false,
      default: 'desc'
    },
    tableFilter: {
      type: Object,
      required: false,
      default: null
    },
    tableHeader: {
      type: [Array, String],
      required: false,
      default: null
    },
    buildRow: {
      type: Function,
      required: false,
      default: null
    },
    buildTable: {
      type: Function,
      required: false,
      default: null
    },
    tableBordered: {
      type: Boolean,
      required: false,
      default: true
    },
    tableTextCenter: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['result', 'sorting'],
  setup (props, { emit }) {
    const route = useRoute()

    const param = ref(null)
    const filter = ref({})
    const page = ref(props.page)
    const size = ref(props.size)
    const sort = ref(props.sort)
    const order = ref(props.order)

    const { loading, result, fetch } = useGqlQuery({
      gqlKey: props.gqlKey,
      gqlQuery: props.gqlQuery,
      gqlVars: props.gqlVars,
      fetchPolicy: props.fetchPolicy
    })

    const update = () => {
      const vars = {
        page: page.value,
        size: size.value,
        sort: sort.value,
        order: order.value
      }
      Object.assign(vars, filter.value)
      fetch(vars)
    }

    const paginate = p => {
      if (typeof p === 'number') page.value = p
      update()
    }

    const filtering = f => {
      filter.value = f
      paginate(1)
    }

    watchEffect(() => {
      param.value = route.params.param || null
    })

    watch(result, (val) => {
      emit('result', val)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    })

    if (!props.tableFilter) update()

    return {
      param,
      result,
      loading,
      filtering,
      paginate,
      update
    }
  }
}
</script>
