<template>
  <VSelect
      :full-width="false"
      :label="settings.label"
      :placeholder="settings.placeholder"
      :options="options"
      v-model="state"
  />
</template>

<script>
import VSelect from '../../elements/VSelect'
import { ref, watch, onMounted } from 'vue'
import { useFilter } from '@/base/use/filter'
import { useGqlQuery } from '@/base/use/gql'
import { useRoute } from 'vue-router'

export default {
  name: 'VQuerySelectField',
  components: { VSelect },
  props: {
    name: {
      type: String,
      required: true
    },
    settings: {
      type: Object,
      required: true
    }
  },
  emits: ['query-selector-ready'],
  setup (props, { emit }) {
    const { filter } = useFilter(props.name)
    const state = ref(null)
    const options = ref([])

    const route = useRoute()
    if (route.query) {
      if (route.query[props.settings.field]) {
        filter.value[props.settings.field] = route.query[props.settings.field]
      }
    }

    const { result, fetch } = useGqlQuery({
      gqlKey: props.settings.gqlKey,
      gqlQuery: props.settings.gqlQuery,
      gqlVars: props.settings.gqlVars
    })

    fetch()

    watch(result, (value) => {
      if (props.settings.all) {
        options.value.push({ label: props.settings.all, value: 'all' })
      }
      const opts = value.items.map(item => {
        return {
          label: item[props.settings.labelField],
          value: item[props.settings.valueField]
        }
      })
      options.value.push(...opts)
      emit('query-selector-ready', props.settings.field)
    })

    const setFilter = () => {
      if (state.value === 'all') {
        filter.value[props.settings.field] = null
      } else if (state.value === 'true') {
        filter.value[props.settings.field] = true
      } else if (state.value === 'false') {
        filter.value[props.settings.field] = false
      } else {
        filter.value[props.settings.field] = state.value
      }
    }

    const getFilter = () => {
      if (filter.value[props.settings.field] !== undefined) {
        if (filter.value[props.settings.field] === null) {
          state.value = 'all'
        } else if (filter.value[props.settings.field] === true) {
          state.value = true
        } else if (filter.value[props.settings.field] === false) {
          state.value = false
        } else {
          state.value = filter.value[props.settings.field]
        }
      } else {
        state.value = props.settings.selected
      }
      setFilter()
    }

    watch(state, () => {
      setFilter()
    })

    onMounted(() => {
      getFilter()
    })

    return {
      state,
      options
    }
  }
}
</script>
