<template>
  <PanelContainer
    v-model="state"
    title="Профиль"
    size="400"
    :btnRight="btnRight"
  >
    Пользователь: <strong>{{ getUserName() }}</strong><br>
    Телефон: <strong>{{ user.phone ? user.phone : '-' }}</strong><br><br>
    <strong>Роли:</strong>
    <p v-for="role in user.roles" :key="role.code">{{ role.name }}</p>
    <br>
    <strong>Группы:</strong>
    <p v-for="group in user.groups" :key="group.code">{{ group.name }}</p>
  </PanelContainer>
</template>

<script setup>
import { useModel } from '@/base/use/model'
import { useUser } from '@/base/use/user'
import { GC_AUTH_TOKEN, GC_USER_EMAIL, GC_USER_ID } from '@/base/settings'

const props = defineProps({
  modelValue: Boolean
})
const emit = defineEmits(['update:modelValue'])
const state = useModel(props, emit)

const btnRight = {
  color: 'danger',
  text: 'выйти',
  action: function () {
    localStorage.setItem(GC_AUTH_TOKEN, null)
    localStorage.setItem(GC_USER_ID, null)
    localStorage.setItem(GC_USER_EMAIL, null)
    location.href = '/'
  },
  visible: true
}

const { getUserName, user } = useUser()
</script>
