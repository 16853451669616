<template>
  <div class="errors" v-if="errors.length">
    <div v-for="(e, i) in errors" :key="'error_' + i" v-html="e"></div>
    <div class="errors-close" @click.stop="clear">+</div>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'VError',
  props: {
    modelValue: {
      type: [String, Array, Object],
      required: true
    }
  },
  setup (props, { emit }) {
    const errors = computed({
      get: () => {
        if (typeof props.modelValue === 'string') {
          return [props.modelValue]
        } else if (props.modelValue instanceof Array) {
          return props.modelValue
        } else if (props.modelValue.graphQLErrors) {
          const e1 = props.modelValue.graphQLErrors.map(x => x.message)
          const e2 = props.modelValue.graphQLErrors.map(x => x.debugMessage)
          return [...e1, ...e2]
        } else {
          return []
        }
      },
      set: (value) => emit('update:modelValue', value)
    })

    function clear () {
      errors.value = []
    }

    return {
      errors,
      clear
    }
  }
}
</script>

<style lang="scss" scoped>
.errors {
  display: block;
  position: relative;
  background-color: #e55353;
  color: #fff;
  font-weight: bold;
  padding: 1rem;
  padding-left: 4rem !important;
  line-height: 1.2;
  font-size: 12px;

  .errors-close {
    font-size: 30px;
    border-radius: 20px;
    position: absolute;
    top: 6px;
    left: 28px;
    cursor: pointer;
    transform: rotate(45deg);
    color: #fff;
    transition: all 280ms ease-in-out;

    &:hover {
      transform: rotate(45deg) scale(1.1);
    }
  }
}
</style>
