const tooltipDirective = (Vue) => {
  Vue.directive('tooltip', {
    mounted (el, binding) {
      init(el, binding)
    },
    updated (el, binding) {
      init(el, binding)
    }
  })
}

function init (el, binding) {
  if (!binding.value) return
  const position = binding.arg || 'bottom'
  const tooltipText = binding.value
  el.setAttribute('position', position)
  el.setAttribute('tooltip', tooltipText)
}

export default tooltipDirective
