<template>
  <div class="input-checkbox">
    <label>
      <input
          v-bind="$attrs"
          type="checkbox"
          :checked="state"
          @change="onChange($event)"
      />
      {{ label }}
      <slot/>
    </label>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'VCheckbox',
  props: {
    modelValue: {
      type: [Boolean, Number],
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup (props, { emit }) {
    const state = computed({
      get: () => typeof props.modelValue === 'boolean' ? props.modelValue : !!props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })

    const onChange = e => {
      state.value = e.target.checked
    }

    const classes = computed(() => 'input-wrapper')

    return {
      state,
      classes,
      onChange
    }
  }
}
</script>
