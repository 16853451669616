import operating from './operating'
// import dispatcher from './dispatcher'
import accounting from './accounting'
import transport from './transport'
// import inventory from './inventory'
// import administrating from './administrating'

export default [
  {
    name: 'Оперативный раздел',
    path: 'operating',
    meta: {
      roles: ['root', 'boss', 'manager', 'financier'],
      groups: []
    },
    items: operating
  },
  // {
  //   name: 'Диспетчерская',
  //   path: 'dispatcher',
  //   meta: {
  //     roles: ['root', 'boss', 'dispatcher'],
  //     groups: ['dispatcher']
  //   },
  //   items: dispatcher
  // },
  {
    name: 'Бухгалтерия',
    path: 'accounting',
    meta: {
      roles: ['root', 'boss', 'financier'],
      groups: ['accounting']
    },
    items: accounting
  },
  {
    name: 'Транспорт',
    path: 'transport',
    meta: {
      roles: ['root', 'boss'],
      groups: ['transport']
    },
    items: transport
  }
  // {
  //   name: 'Склад',
  //   path: 'inventory',
  //   meta: {
  //     roles: ['root', 'boss'],
  //     groups: ['inventory']
  //   },
  //   items: inventory
  // },
  // {
  //   name: 'Администрирование',
  //   path: 'administrating',
  //   meta: {
  //     roles: ['root', 'boss'],
  //     groups: ['administrating']
  //   },
  //   items: administrating
  // }
]
