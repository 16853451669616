<template>
  <div class="panel-container-wrapper" :style="{ display }">
    <div class="panel-container" :style="{ right, width }">
      <div class="panel-container-label">
        <div class="panel-container-close-btn" @click="emit('update:modelValue', false)">
          <div class="panel-container-close-btn-inner"></div>
        </div>
        <span class="panel-container-label-text"></span>
      </div>
      <div class="panel-container-title panel-container-title-blue">
        <div class="title-text">{{ props.title }}</div>
        <div>
          <div class="title-btn" v-if="btnLeft">
            <button :class="'btn btn-' + btnLeft.color + ' btn-sm'" @click="btnLeft.action">{{ btnLeft.text }}</button>
          </div>
          <div class="title-btn ml-2" v-if="btnRight && btnRight.visible">
            <button :class="'btn btn-' + btnRight.color + ' btn-sm'" @click="btnRight.action">{{ btnRight.text }}</button>
          </div>
        </div>
      </div>
      <div class="panel-container-content">
        <div class="tab-content">
          <div class="tab-pane fade show active">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'

const props = defineProps({
  modelValue: Boolean,
  title: String,
  size: String,
  btnLeft: Object,
  btnRight: Object
})

const emit = defineEmits(['update:modelValue'])

const display = ref('none')
const right = ref('-' + props.size + 'px')
const width = ref(props.size + 'px')
const ready = ref(false)

const visible = computed(() => props.modelValue)

const btnLeft = computed(() => props.btnLeft)
const btnRight = computed(() => props.btnRight)

watch(visible, value => {
  if (value) {
    display.value = 'block'
    setTimeout(() => {
      right.value = '0px'
    }, 100)
  } else {
    right.value = '-' + props.size + 'px'
    setTimeout(() => {
      display.value = 'none'
    }, 200)
  }
})
</script>
