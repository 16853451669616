import moment from 'moment'

export const rebuildObject = (data, fields) => {
  if (!data) return null
  const res = {}
  for (const j in fields) {
    const el = fields[j]
    res[el] = data[el]
  }
  return res
}

export const rebuildArray = (data, fields) => {
  if (!data) return null
  let res = {}
  const arr = []
  for (const i in data) {
    const item = data[i]
    for (const j in fields) {
      const el = fields[j]
      res[el] = item[el]
    }
    arr.push(res)
    res = {}
  }
  return arr
}

export const rub = (val) => {
  if (val === 0) return '0'
  return number_format(val, 2, '.', ' ') + (val !== 0 ? ' ₽' : '')
}

export const round1 = (val) => {
  if (val === 0) return '0'
  return number_format(val, 1, '.', ' ')
}

export const round2 = (val) => {
  if (val === 0) return '0'
  return number_format(val, 2, '.', ' ')
}

function number_format (number, decimals = 0, dec_point = '.', thousands_sep = ',') {
  const sign = number < 0 ? '-' : ''
  const s_number = Math.abs(parseInt(number = (+number || 0).toFixed(decimals))) + ''
  const len = s_number.length
  const tchunk = len > 3 ? len % 3 : 0
  const ch_first = (tchunk ? s_number.substr(0, tchunk) + thousands_sep : '')
  const ch_rest = s_number.substr(tchunk)
    .replace(/(\d\d\d)(?=\d)/g, '$1' + thousands_sep)
  const ch_last = decimals
    ? dec_point + (Math.abs(number) - s_number)
      .toFixed(decimals)
      .slice(2)
    : ''
  return sign + ch_first + ch_rest + ch_last
}

export const diffMonths = (date) => {
  const m = parseInt(moment(date).format('M'))
  const y = parseInt(moment(date).format('Y'))
  const mn = parseInt(moment().format('M'))
  const yn = parseInt(moment().format('Y'))
  return (mn + (12 * (yn - y))) - m + 1
}

export const minToTime = (minutes) => {
  const h = Math.trunc(minutes / 60)
  const m = minutes % 60
  return `${h} ч ${m} м`
}

export function once (fn, context) {
  let result
  return function () {
    if (fn) {
      result = fn.apply(context || this, arguments)
      fn = null
    }
    return result
  }
}
