<template>
  <div :class="classes">
    <label>
      {{ label }}
      <span v-if="required" class="text-danger"><i class="fw-normal" :style="{ 'font-size': '12px' }">*</i></span>
      <input
          v-bind="$attrs"
          type="text"
          :placeholder="placeholder"
          v-model="search"
          autocomplete="off"
          :disabled="disable"
          :required="required"
          @input="query()"
          @click="clearList()"
          @keypress.enter="clearList()"
      />
    </label>
    <div class="clear-button" v-if="disable">
      <button v-if="!disabled" type="button" class="btn btn-danger btn-in" @click="clearItem()">X</button>
    </div>
    <div class="list" v-if="list.length">
      <div class="list-group">
        <button
            v-for="e in list"
            :key="e.out"
            type="button"
            class="list-group-item list-group-item-action item-selection"
            :style="from === 'auto' && !e.active ? 'color: red !important;' : null"
            @click="setItem(e)"
        >
          {{ e.in }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useApolloClient } from '@vue/apollo-composable'
import { ref, computed, onMounted, watch } from 'vue'
import gql from 'graphql-tag'

export default {
  name: 'VAutocomplete',
  props: {
    modelValue: {
      type: [String, Number, Object],
      required: false,
      default: null
    },
    default: {
      type: [String, Number, Object],
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    in: {
      type: String,
      required: true
    },
    out: {
      type: String,
      required: true
    },
    from: {
      type: String,
      required: true
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    reset: {
      type: Boolean,
      required: false,
      default: false
    },
    returnLabelValue: {
      type: Boolean,
      required: false,
      default: false
    },
    where: {
      type: Array,
      required: false,
      default: null
    }
  },
  emits: ['set-item', 'set-text', 'update:modelValue', 'update:reset'],
  setup (props, { emit }) {
    const apollo = useApolloClient()

    const list = ref([])
    const disable = ref(false)
    const search = ref(null)

    const input = computed(() => props.default)
    const disbl = computed(() => props.disabled)
    const _reset = computed(() => props.reset)

    const setItem = item => {
      search.value = item.in
      disable.value = true
      list.value = []
      emit('update:modelValue', !props.returnLabelValue ? item.out : { label: item.in, value: item.out })
      emit('set-item', item.out)
      emit('set-text', search.value || null)
    }

    const clearList = () => {
      list.value = []
    }

    const clearItem = () => {
      clearList()
      search.value = null
      disable.value = false
      emit('update:modelValue', null)
    }

    const query = (reverse = false, defaultVal = null) => {
      emit('set-text', search.value || null)
      if (!search.value && reverse === false) {
        clearItem()
        return
      }

      apollo.client.query({
        query: gql`
          query ($from: String!, $in: String!, $out: String! $val: String!, $reverse: Boolean!, $where: [String]) {
            Ac (from: $from, in: $in, out: $out, val: $val, reverse: $reverse, where: $where) {
              in
              out
              active
            }
          }
        `,
        fetchPolicy: 'network-only',
        variables: {
          from: props.from,
          in: props.in,
          out: props.out,
          val: (!reverse ? search.value : defaultVal || props.modelValue) + '',
          where: props.where,
          reverse
        }
      }).then(res => {
        const data = res.data.Ac
        if (!reverse) {
          list.value = data
        } else if (data) {
          search.value = data[0].in
          disable.value = true
          list.value = []
        }
      }).catch(err => {
        console.error(err)
      })
    }

    onMounted(() => {
      if (props.modelValue) query(true)
    })

    watch(input, (val) => {
      if (val) {
        query(true, val)
      } else {
        clearItem()
      }
    })

    watch(disbl, (val, prev) => {
      if (val === false && prev === true) {
        clearItem()
      }
    })

    watch(_reset, (val) => {
      if (val) {
        clearItem()
        emit('update:reset', false)
      }
    }, { immediate: true })

    return {
      list,
      disable,
      search,
      query,
      setItem,
      clearList,
      clearItem,
      classes: computed(() => props.fullWidth ? 'ac-full-wrapper' : 'ac-wrapper')
    }
  }
}
</script>

<style lang="scss" scoped>
.ac-wrapper {
  position: relative;

  label {
    font-size: 12px;
    color: #4f5d73;
    font-weight: 700;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  input {
    display: block;
    height: 28px;
    margin: 0;
    padding: 0 10px;
    border: 1px solid #009df1;

    &:focus {
      outline: none;
      border-color: #ffc168;
    }
  }

  .clear-button {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .btn-in {
    padding: 7.5px 10px !important;
    border-radius: 0 !important;
  }

  .list {
    min-width: 256px;
    position: absolute;
    width: 100%;
    background-color: white;
    z-index: 9999;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    white-space: normal;
    .list-group {
      button {
        color: #2e2e2e !important;
        font-size: 12px!important;
        font-weight: 400!important;
      }
    }
  }

  .text-muted {
    font-size: 12px;
  }
}

.ac-full-wrapper {
  position: relative;
  width: 100%;

  label {
    font-size: 12px;
    color: #4f5d73;
    font-weight: 700;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  input {
    display: block;
    width: 100%;
    height: 28px;
    margin: 0;
    padding: 0 10px;
    border: 1px solid #009df1;

    &:focus {
      outline: none;
      border-color: #ffc168;
    }
  }

  .clear-button {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .btn-in {
    padding: 7.5px 10px !important;
    border-radius: 0 !important;
  }

  .list {
    min-width: 256px;
    position: absolute;
    width: 100%;
    background-color: white;
    z-index: 9999;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    white-space: normal;
    .list-group {
      button {
        color: #2e2e2e !important;
        font-size: 12px!important;
        font-weight: 400!important;
      }
    }
  }

  .text-muted {
    font-size: 12px;
  }
}
.item-selection {
  &:hover {
    background-color: #ffc168;
  }
}
.not-active {
  color: red !important;
}
</style>
