<template>
  <ul class="nav nav-tabs modify-nav" >
    <li class="nav-item" v-for="(tab, n) in tabs" :key="'tab-'+n">
      <a class="nav-link" :class="{ 'active': tab.active }" href="#" @click.prevent="setTab(n)">{{ tab.name }}</a>
    </li>
  </ul>
  <div class="tab-content modify-content">
    <div class="tab-pane" :class="{ 'show active': tab.active }" v-for="(tab, n) in tabs" :key="'tab-content-'+n">
      <slot :name="'tab-'+n"/>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'

export default {
  name: 'VTabs',
  props: {
    names: {
      type: Array,
      required: true
    }
  },
  setup (props) {
    const _tabs = computed(() => {
      return props.names.map(item => {
        return { name: item, active: false }
      })
    })

    const tabs = ref(_tabs.value)

    function setTab (n) {
      tabs.value.forEach(tab => { tab.active = false })
      tabs.value[n].active = true
    }

    onMounted(() => {
      tabs.value[0].active = true
    })

    return {
      tabs,
      setTab
    }
  }
}
</script>

<style lang="scss" scoped>
.modify-nav {
  .active {
    font-weight: 700;
    border-radius: 0 !important;
    background-color: transparent !important;
    border-bottom: 1px solid #eef2f4 !important;
  }
}
.modify-content {
  padding: 1rem;
  background-color: transparent;
  border: 1px solid #dee2e6;
  border-top-color: transparent;
}
</style>
