<template>
  <Modal
      size="sm"
      centered
      :title="getUserName()"
      :confirm="{ text: 'Выйти из системы', color: 'info'}"
      @confirm="confirm"
  >
  </Modal>
</template>

<script>
import Modal from '@/base/components/modal/Modal'
import { useCurrentModal } from '@/base/use/modal'
import { useUser } from '@/base/use/user'
import { GC_AUTH_TOKEN, GC_USER_EMAIL, GC_USER_ID } from '@/base/settings'

export default {
  name: 'Profile',
  components: { Modal },
  setup () {
    const { hide } = useCurrentModal('Profile')
    const { getUserName } = useUser()

    function logout () {
      localStorage.setItem(GC_AUTH_TOKEN, null)
      localStorage.setItem(GC_USER_ID, null)
      localStorage.setItem(GC_USER_EMAIL, null)
      location.href = '/'
    }

    function confirm () {
      hide()
      logout()
    }

    return {
      confirm,
      getUserName
    }
  }
}
</script>
