import { createRouter, createWebHashHistory } from 'vue-router'

import Container from '@/base/layout/Container'
import Home from '@/views/Home'

import operating from './operating'
// import dispatcher from './dispatcher'
import accounting from './accounting'
import transport from './transport'
// import inventory from './inventory'
// import administrating from './administrating'

const routes = [
  {
    path: '/reports',
    component: {
      template: '<router-view></router-view>'
    },
    children: [
      {
        path: 'route',
        component: () => import(/* webpackChunkName: "report-route" */'@/views/reports/ReportRoute')
      },
      {
        path: 'stop',
        component: () => import(/* webpackChunkName: "report-stop" */'@/views/reports/ReportStop')
      }
    ]
  },
  {
    path: '/',
    component: Container,
    children: [
      {
        path: '',
        name: 'Home',
        meta: { label: 'Главная' },
        component: Home
      },
      {
        path: 'operating',
        meta: { label: 'Оперативный раздел' },
        component: {
          template: '<router-view></router-view>'
        },
        children: operating
      },
      // {
      //   path: 'dispatcher',
      //   meta: { label: 'Диспетчерская' },
      //   component: {
      //     template: '<router-view></router-view>'
      //   },
      //   children: dispatcher
      // },
      {
        path: 'accounting',
        meta: { label: 'Бухгалтерия' },
        component: {
          template: '<router-view></router-view>'
        },
        children: accounting
      },
      {
        path: 'transport',
        meta: { label: 'Транспорт' },
        component: {
          template: '<router-view></router-view>'
        },
        children: transport
      }
      // {
      //   path: 'inventory',
      //   meta: { label: 'Склад' },
      //   component: {
      //     template: '<router-view></router-view>'
      //   },
      //   children: inventory
      // },
      // {
      //   path: 'administrating',
      //   meta: { label: 'Администрирование' },
      //   component: {
      //     template: '<router-view></router-view>'
      //   },
      //   children: administrating
      // }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "not-found" */'@/views/NotFound')
  }
]

const router = createRouter({
  history: createWebHashHistory('/'),
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

export default router
