<template>
  <div class="line" v-if="!collapsed"></div>
  <div v-show="!collapsed">
    <div class="small help">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VHelp',
  props: {
    collapsed: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.help {
  line-height: 1.2;
  background-color: white;
}
</style>
