import { createApp } from 'vue'
import router from './router'

import { DefaultApolloClient } from '@vue/apollo-composable'
import { apolloClient } from '@/base/apollo'

import components from '@/base/components'
import directives from '@/base/directives'

import Toast from 'vue-toastification'
import Notifications from '@kyvg/vue3-notification'

import App from './views/App'

const app = createApp(App)

// register all components
components(app)

// register all directives
directives(app)

app.use(Toast, {
  position: 'top-right',
  maxToasts: 10,
  newestOnTop: true,
  timeout: 5000,
  hideProgressBar: true
})

app.use(router)
app.use(Notifications)
app.provide(DefaultApolloClient, apolloClient)
app.mount('#app')

/*
<!--
      ___           ___
     /\  \         /\__\
    /  \  \       / / _/_
   / /\ \  \     / / /\  \
  / / /  \  \   / / /  \  \
 / /_/ /\ \__\ / / / /\ \__\
 \ \/ /  \/__/ \ \/ / / /  /
  \  /__/       \  / / /  /
   \ \  \        \/_/ /  /
    \ \__\         / /  /
     \/__/         \/__/

-->
*/
