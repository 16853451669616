<template>
  <div :class="classes">
    <label>
      {{ label }}
      <span v-if="required" class="text-danger"><i class="fw-normal" :style="{ 'font-size': '12px' }">*</i></span>
      <textarea
          v-bind="$attrs"
          :placeholder="placeholder"
          v-model="state"
      />
    </label>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'VTextarea',
  props: {
    modelValue: {
      type: [String, Number],
      required: false,
      default: null
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props, { emit }) {
    const state = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })

    const classes = computed(() => props.fullWidth ? 'input-full-wrapper' : 'input-wrapper')

    return {
      state,
      classes
    }
  }
}
</script>
