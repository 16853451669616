<template>
  <div class="v-loader-wrap" v-if="!isCubics">
    <div id="v-loader"></div>
  </div>
  <div v-else class="v-loader-wrap">
    <img id="v-cubics" src="../../../assets/cubics.gif"/>
  </div>
</template>

<script>
import { useLoader } from '@/base/use/loader'
export default {
  name: 'VLoader',
  setup () {
    const { isCubics } = useLoader()
    return { isCubics }
  }
}
</script>

<style>
.v-loader-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: wait;
  z-index: 9999;
}

#v-loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 100px;
  height: 100px;
  margin: -50px 0 0 -50px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #409eff;
  animation: spin 3s linear infinite;
}

#v-loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #409eff;
  animation: spin 2s linear infinite;
}

#v-loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: orange;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#v-cubics {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  animation: spin-left 3s linear infinite;
}
</style>
