<template>
  <div class="form-inline">
    <VPeriodField
        class="me-1 mb-1"
        v-if="settings.period"
        :key="'period-' + renderKey"
        :name="settings.name"
        :settings="settings.period"
    />
  </div>
  <div class="form-inline">
    <template v-if="settings.date">
      <VDateField
          class="me-1"
          :key="'date-' + renderKey"
          :name="settings.name"
          :settings="settings.date"
      />
    </template>
    <template v-if="settings.month">
      <VMonthField
          class="me-1"
          :key="'month-' + renderKey"
          :name="settings.name"
          :settings="settings.month"
          @auto-update="search()"
      />
    </template>
    <template v-if="settings.textInputs">
      <VInputField
          class="me-1"
          v-for="(textInput, i) in settings.textInputs"
          :key="'input-' + renderKey + i"
          :name="settings.name"
          :settings="textInput"
      />
    </template>
    <template v-if="settings.querySelectors">
      <VQuerySelectField
          class="me-1"
          v-for="(querySelector, i) in settings.querySelectors"
          :key="'qs-' + renderKey + i"
          :name="settings.name"
          :settings="querySelector"
          @query-selector-ready="querySelectorReady"
      />
    </template>
    <template v-if="settings.arraySelectors">
      <VArraySelectField
          class="me-1"
          v-for="(arraySelector, i) in settings.arraySelectors"
          :key="'as-' + renderKey + i"
          :name="settings.name"
          :settings="arraySelector"
      />
    </template>
    <template v-if="settings.autocompletes">
      <VAutocompleteField
          class="me-1"
          v-for="(autocomplete, i) in settings.autocompletes"
          :key="'ac-' + renderKey + i"
          :name="settings.name"
          :settings="autocomplete"
      />
    </template>
    <template v-if="settings.checkboxes">
      <VCheckboxField
          class="me-1"
          v-for="(checkbox, i) in settings.checkboxes"
          :key="'ch-' + renderKey + i"
          :name="settings.name"
          :settings="checkbox"
      />
    </template>
    <VButton class="me-2" color="primary" text="Найти" icon="cisSearch" @click.prevent="search()"/>
    <VButton color="dark" text="Сброс" icon="cisX" @click.prevent="reset()"/>
    <slot/>
  </div>
</template>

<script>
import VDateField from './fields/VDateField'
import VPeriodField from './fields/VPeriodField'
import VInputField from './fields/VInputField'
import VArraySelectField from './fields/VArraySelectField'
import VAutocompleteField from './fields/VAutocompleteField'
import VCheckboxField from './fields/VCheckboxField'
import VQuerySelectField from './fields/VQuerySelectField'
import VMonthField from './fields/VMonthField'
import { ref, onMounted, watch, nextTick } from 'vue'
import { useFilter } from '../../use/filter'

export default {
  name: 'VFilter',
  components: {
    VMonthField,
    VDateField,
    VPeriodField,
    VArraySelectField,
    VAutocompleteField,
    VCheckboxField,
    VInputField,
    VQuerySelectField
  },
  props: {
    settings: {
      type: Object,
      required: true
    }
  },
  emits: ['filtering'],
  setup (props, { emit }) {
    const { filter, clear, isNeedRerender, needRerenderClear } = useFilter(props.settings.name)

    const key = ref(1)
    const renderKey = ref('field')
    const selectorsReady = ref({})

    const search = () => {
      nextTick(() => {
        emit('filtering', filter.value)
      })
    }

    const querySelectorReady = field => {
      selectorsReady.value[field] = true
      for (const key in selectorsReady) {
        if (selectorsReady.value[key]) return
      }
      search()
    }

    const reset = () => {
      clear()
      renderKey.value = 'field' + key.value++
      nextTick(() => {
        search()
      })
    }

    watch(isNeedRerender, (value) => {
      if (value) {
        renderKey.value = 'field' + key.value++
        needRerenderClear()
        nextTick(() => {
          search()
        })
      }
    })

    onMounted(() => {
      if (props.settings.querySelectors) {
        props.settings.querySelectors.forEach(item => {
          selectorsReady.value[item.field] = false
        })
      } else {
        search()
      }
    })

    return {
      key,
      renderKey,
      search,
      reset,
      querySelectorReady
    }
  }
}
</script>
