import gql from 'graphql-tag'

export const DELETE = gql`
mutation ($key: String! $id: Int!) {
  Delete (key: $key, id: $id) {
    id,
    code,
    success
  }
}`
