import { BatchHttpLink } from 'apollo-link-batch-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { createUploadLink } from 'apollo-upload-client'

import { GC_AUTH_TOKEN, BASE_URI } from './settings'

const token = localStorage.getItem(GC_AUTH_TOKEN) || ''

const httpLink = new BatchHttpLink({
  uri: BASE_URI + '/q/secret?',
  headers: {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export const apolloClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
  shouldBatch: true,
  connectToDevTools: false
})

const httpUploadLink = createUploadLink({
  uri: BASE_URI + '/q/secret?',
  headers: {
    Authorization: `Bearer ${token}`
  }
})

export const apolloUploadClient = new ApolloClient({
  link: httpUploadLink,
  cache: new InMemoryCache(),
  shouldBatch: true,
  connectToDevTools: false
})

const httpLinkNoAuth = new BatchHttpLink({
  uri: BASE_URI + '/q?',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export const apolloClientNoAuth = new ApolloClient({
  link: httpLinkNoAuth,
  cache: new InMemoryCache(),
  shouldBatch: true,
  connectToDevTools: false
})
