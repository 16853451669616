export default [
  {
    type: 'link',
    show: true,
    name: 'Табель учета',
    path: 'report',
    comp: 'RaceReportList',
    icon: 'cisGrid'
  },
  // {
  //   type: 'group',
  //   show: true,
  //   collapse: true,
  //   name: 'Бизнес-процессы',
  //   path: 'bp',
  //   icon: 'cisLoopSquare',
  //   items: [
  //     {
  //       type: 'link',
  //       show: true,
  //       name: 'Взаиморасчет с перевозчиком',
  //       path: 'periods',
  //       comp: 'BpPeriodList',
  //       icon: 'cisMobileSms'
  //     }
  //   ]
  // },
  // {
  //   type: 'menu',
  //   show: true,
  //   collapse: true,
  //   name: 'Расчетные периоды (бот)',
  //   path: 'period',
  //   comp: 'SupplierPeriodList',
  //   edit: 'SupplierPeriodEdit',
  //   icon: 'cisSend',
  //   items: [
  //     {
  //       type: 'link',
  //       show: true,
  //       name: 'Бот-пользователи',
  //       path: 'botuser',
  //       comp: 'BotUserList',
  //       icon: 'cisPeople'
  //     }
  //   ]
  // },
  {
    type: 'menu',
    show: true,
    collapse: true,
    name: 'Планирование маршрутов',
    path: 'planing',
    comp: 'PlaningIndex',
    icon: 'cisSettingsAlt',
    items: [
      {
        type: 'link',
        show: true,
        name: 'Типы смен водителей',
        path: 'shift',
        comp: 'ShiftTypeList',
        edit: 'ShiftTypeEdit',
        icon: 'cisList'
      }
    ]
  }
]
