<template>
  <VAutocomplete
      v-if="ready"
      :id="settings.name + '-' + settings.field"
      :full-width="false"
      :label="settings.label"
      :placeholder="settings.placeholder"
      :in="settings.in"
      :out="settings.out"
      :from="settings.from"
      v-model="state"
  />
</template>

<script>
import VAutocomplete from '../../elements/VAutocomplete'
import { ref, onMounted, onUnmounted, watch } from 'vue'
import { useFilter } from '@/base/use/filter'
import { useRoute } from 'vue-router'

export default {
  name: 'VAutocompleteField',
  components: { VAutocomplete },
  props: {
    name: {
      type: String,
      required: true
    },
    settings: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const { filter } = useFilter(props.name)
    const ready = ref(false)
    const state = ref(null)

    const route = useRoute()
    if (route.query) {
      if (props.settings.field === 'id') {
        filter.value[props.settings.field] = Number(route.query[props.settings.field])
      } else {
        filter.value[props.settings.field] = route.query[props.settings.field]
      }
    }

    const setFilter = () => {
      if (props.settings.field === 'id') {
        filter.value[props.settings.field] = state.value ? Number(state.value) : null
      } else {
        filter.value[props.settings.field] = state.value || null
      }
    }

    const getFilter = () => {
      if (filter.value[props.settings.field]) {
        if (props.settings.field === 'id') {
          state.value = Number(filter.value[props.settings.field])
        } else {
          state.value = filter.value[props.settings.field]
        }
      } else {
        state.value = null
        setFilter()
      }
    }

    watch(state, () => {
      setFilter()
    })

    onMounted(() => {
      getFilter()
      ready.value = true
    })

    onUnmounted(() => {
      ready.value = false
    })

    return {
      ready,
      state
    }
  }
}
</script>
