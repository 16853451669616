import { ref } from 'vue'

const menu = ref([])
const sidebar = ref(true)

const useMenu = () => {
  const setSection = section => {
    menu.value = section
    sidebar.value = true
  }

  const sidebarToggle = () => {
    sidebar.value = !sidebar.value
  }

  const sidebarShow = () => {
    sidebar.value = true
  }

  const sidebarHide = () => {
    sidebar.value = false
  }

  return {
    menu,
    setSection,
    sidebar,
    sidebarToggle,
    sidebarShow,
    sidebarHide
  }
}

export {
  useMenu
}
