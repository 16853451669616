import { ref, computed, shallowRef } from 'vue'

const errors = ref([])
const needHide = ref(false)
const componentContainer = shallowRef({})
const currentComponent = shallowRef(null)

/**
 * @param {Object[]|Object} modals
 */
export function useModals (modals = null) {
  if (modals instanceof Array) {
    modals.forEach(item => {
      if (item.name && item.comp) {
        componentContainer.value[item.name] = {
          comp: item.comp,
          data: {},
          callback: () => {
          }
        }
      }
    })
  } else if (modals instanceof Object) {
    componentContainer.value[modals.name] = {
      comp: modals.comp,
      data: {},
      callback: () => {
      }
    }
  }

  const show = (name, data = {}, callback = () => {}) => {
    errors.value = []
    needHide.value = false
    componentContainer.value[name].data = data
    componentContainer.value[name].callback = callback
    currentComponent.value = componentContainer.value[name].comp
  }

  const hide = () => {
    needHide.value = true
  }

  const destroy = () => {
    currentComponent.value = null
    needHide.value = false
  }

  const modal = computed(() => currentComponent.value)

  return {
    modal,
    show,
    hide,
    destroy,
    needHide,
    errors
  }
}

/**
 * @param {string} name
 */
export function useCurrentModal (name) {
  return {
    hide: () => {
      needHide.value = true
    },
    data: componentContainer.value[name].data,
    callback: (data = {}) => {
      componentContainer.value[name].callback(data)
    },
    setErrors: (e) => {
      if (typeof e === 'string') {
        errors.value.push(e)
      } else if (e instanceof Array) {
        errors.value = e
      } else if (e.graphQLErrors) {
        const e1 = e.graphQLErrors.map(x => x.message)
        const e2 = e.graphQLErrors.map(x => x.debugMessage)
        errors.value = [...e1, ...e2]
      }
    },
    clearErrors: () => { errors.value = [] },
    isErrors: () => errors.value.length
  }
}
