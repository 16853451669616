// containers
import EditContainer from './containers/EditContainer'
import ListContainer from './containers/ListContainer'
import PanelContainer from './containers/PanelContainer'
// elements
import VAutocomplete from './elements/VAutocomplete'
import VCheckbox from './elements/VCheckbox'
import VHelp from './elements/VHelp'
import VInput from './elements/VInput'
import VListInput from './elements/VListInput'
import VSelect from './elements/VSelect'
import VTabs from './elements/VTabs'
import VTextarea from './elements/VTextarea'
import VButton from './elements/VButton'
// error
import VError from './error/VError'
// filter
import VFilter from './filter/VFilter'
// table
import VTable from './table/VTable'
// icon
import VIcon from './icon/VIcon'
// loader
import VLoader from './loader/VLoader'
// modal
import Modal from './modal/Modal'

// register all components
const components = (app) => {
  const Vue = app // allow phpstorm to recognise global components
  Vue.component('EditContainer', EditContainer)
  Vue.component('ListContainer', ListContainer)
  Vue.component('PanelContainer', PanelContainer)
  Vue.component('VAutocomplete', VAutocomplete)
  Vue.component('VCheckbox', VCheckbox)
  Vue.component('VHelp', VHelp)
  Vue.component('VInput', VInput)
  Vue.component('VListInput', VListInput)
  Vue.component('VSelect', VSelect)
  Vue.component('VTabs', VTabs)
  Vue.component('VTextarea', VTextarea)
  Vue.component('VButton', VButton)
  Vue.component('VError', VError)
  Vue.component('VFilter', VFilter)
  Vue.component('VTable', VTable)
  Vue.component('VIcon', VIcon)
  Vue.component('VLoader', VLoader)
  Vue.component('Modal', Modal)
}

export default components
