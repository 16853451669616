<template>
  <Modal
      title="Подтвердите удаление"
      :confirm="{ text: 'Удалить', color: 'danger-light'}"
      @confirm="confirm"
      centered
  >
    <div v-html="data.text"></div>
  </Modal>
</template>

<script>
import Modal from '@/base/components/modal/Modal'
import { useCurrentModal } from '@/base/use/modal'
import { DELETE } from '@/gql/delete'
import { useApolloClient } from '@vue/apollo-composable'
import { useToast } from 'vue-toastification'

export default {
  name: 'DeleteModal',
  components: { Modal },
  setup () {
    const { hide, data, callback, setErrors, clearErrors } = useCurrentModal('DeleteModal')

    const apollo = useApolloClient()

    const toast = useToast()

    function confirm () {
      clearErrors()
      apollo.client.mutate({
        mutation: DELETE,
        variables: { key: data.key, id: data.id }
      }).then(() => {
        hide()
        toast('Удалено')
        callback()
      }).catch(e => {
        setErrors(e)
        toast.error('Ошибка')
      })
    }

    return {
      confirm,
      data
    }
  }
}
</script>
