export const GC_USER_ID = 'graphcool-user-id'
export const GC_USER_EMAIL = 'graphcool-user-email'
export const GC_AUTH_TOKEN = 'graphcool-auth-token'

export const BASE_URI = (process.env.NODE_ENV !== 'production')
  ? process.env.VUE_APP_API_URL_DEVELOPMENT
  : process.env.VUE_APP_API_URL_PRODUCTION

export const BASE_WS = (process.env.NODE_ENV !== 'production')
  ? process.env.VUE_APP_WS_DEVELOPMENT
  : process.env.VUE_APP_WS_PRODUCTION

export const VERSION = process.env.VUE_APP_VERSION
