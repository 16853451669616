import { computed, ref } from 'vue'
import { CURRENT_USER } from '@/gql/user/user'
import { useApolloClient } from '@vue/apollo-composable'

const user = ref(null)

const useUser = () => {
  const loading = ref(false)
  const needLogin = ref(false)

  const apollo = useApolloClient()

  const fetch = () => {
    user.value = null
    loading.value = true
    needLogin.value = false
    apollo.client.query({
      query: CURRENT_USER,
      variables: {},
      fetchPolicy: 'no-cache'
    }).then(({ data }) => {
      loading.value = false
      user.value = data.CurrentUser
    }).catch(() => {
      loading.value = false
      needLogin.value = true
    })
  }

  const getUserRoles = () => {
    const roles = []
    if (!user.value) return false
    const rawRoles = user.value.roles
    if (!rawRoles.length) return false
    rawRoles.forEach((role) => {
      roles.push(role.code)
    })
    return (roles.length > 0) ? roles : false
  }

  const getUserGroups = () => {
    const groups = []
    if (!user.value) return false
    const rawGroups = user.value.groups
    if (!rawGroups.length) return false
    rawGroups.forEach((group) => {
      groups.push(group.code)
    })
    return (groups.length > 0) ? groups : false
  }

  const checkAccess = (meta) => {
    let _access = true
    const routeRoles = meta.roles
    const routeGroups = meta.groups
    const userRoles = getUserRoles()
    const userGroups = getUserGroups()
    if (routeRoles && userRoles) {
      _access = routeRoles.some(x => userRoles.includes(x))
    }
    if (!_access) {
      if (routeGroups && userGroups) {
        _access = routeGroups.some(x => userGroups.includes(x))
      }
    }
    return _access
  }

  const getUserName = () => user.value.name
  const computedNotifies = computed(() => user.value && user.value.notifies ? user.value.notifies : [])
  const computedNotifiesCount = computed(() => user.value && user.value.notifies ? user.value.notifies.length : 0)

  const getRoleBadge = () => {
    let rolesString = ''
    if (getUserRoles()) {
      getUserRoles().forEach(code => {
        if (code === 'root') {
          rolesString += '<div class="ms-1 text-danger">админ</div>'
        } else if (code === 'boss') {
          rolesString += '<div class="ms-1 text-primary">босс</div>'
        } else if (code === 'manager') {
          rolesString += '<div class="ms-1 text-success">менеджер</div>'
        } else if (code === 'financier') {
          rolesString += '<div class="ms-1 text-success">бухгалтер</div>'
        } else if (code === 'employer') {
          rolesString += '<div class="ms-1 text-light">сотрудник</div>'
        } else if (code === 'dispatcher') {
          rolesString += '<div class="ms-1 text-warning">диспетчер</div>'
        }
      })
    }
    return rolesString
  }

  const pushNotify = notify => {
    if (user.value && user.value.notifies) {
      user.value.notifies.unshift(notify)
    }
  }

  const removeNotify = id => {
    if (user.value && user.value.notifies) {
      const _notifies = []
      user.value.notifies.forEach(x => {
        if (x.id !== id) _notifies.push(x)
      })
      user.value.notifies = _notifies
    }
  }

  const clearNotifies = ids => {
    if (user.value && user.value.notifies) {
      user.value.notifies = []
    }
  }

  const setNotifies = notifies => {
    if (user.value && user.value.notifies) {
      user.value.notifies = notifies
    }
  }

  return {
    fetch,
    getUserRoles,
    getUserGroups,
    checkAccess,
    user,
    loading,
    needLogin,
    getUserName,
    getRoleBadge,
    computedNotifies,
    computedNotifiesCount,
    pushNotify,
    removeNotify,
    setNotifies,
    clearNotifies
  }
}

export {
  useUser
}
