<template>
  <VSelect
      :full-width="false"
      :label="settings.label"
      :placeholder="settings.placeholder"
      :options="options"
      v-model="state"
  />
</template>

<script>
import VSelect from '../../elements/VSelect'
import { ref, watch, onMounted, computed } from 'vue'
import { useFilter } from '@/base/use/filter'
import { useRoute } from 'vue-router'

export default {
  name: 'VArraySelectField',
  components: { VSelect },
  props: {
    name: {
      type: String,
      required: true
    },
    settings: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const { filter } = useFilter(props.name)
    const state = ref(null)

    const route = useRoute()
    if (route.query) {
      if (route.query[props.settings.field]) {
        filter.value[props.settings.field] = route.query[props.settings.field]
      }
    }

    const options = computed(() => {
      const _options = []
      if (props.settings.all) {
        _options.push({ label: props.settings.all, value: 'all' })
      }
      _options.push(...props.settings.options)
      return _options
    })

    const setFilter = () => {
      if (state.value === 'all') {
        filter.value[props.settings.field] = null
      } else if (state.value === 'true') {
        filter.value[props.settings.field] = true
      } else if (state.value === 'false') {
        filter.value[props.settings.field] = false
      } else {
        filter.value[props.settings.field] = state.value
      }
    }

    const getFilter = () => {
      if (filter.value[props.settings.field] !== undefined) {
        if (filter.value[props.settings.field] === null) {
          state.value = 'all'
        } else if (filter.value[props.settings.field] === true) {
          state.value = true
        } else if (filter.value[props.settings.field] === false) {
          state.value = false
        } else {
          state.value = filter.value[props.settings.field]
        }
      } else {
        state.value = props.settings.selected
      }
      setFilter()
    }

    watch(state, () => {
      setFilter()
    })

    onMounted(() => {
      getFilter()
    })

    return {
      state,
      options
    }
  }
}
</script>
