<template>
  <VInput
      :id="settings.name + '-' + settings.field"
      :full-width="false"
      v-bind="$attrs"
      v-model="state"
      :label="settings.label"
      :placeholder="settings.placeholder"
  />
</template>

<script>
import VInput from '../../elements/VInput'
import { ref, watch, onMounted } from 'vue'
import { useFilter } from '@/base/use/filter'
import { useRoute } from 'vue-router'

export default {
  name: 'VInputField',
  components: { VInput },
  props: {
    name: {
      type: String,
      required: true
    },
    settings: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const { filter } = useFilter(props.name)
    const state = ref(null)

    const route = useRoute()
    if (route.query) {
      if (route.query[props.settings.field]) {
        if (props.settings.field === 'id') {
          filter.value[props.settings.field] = Number(route.query[props.settings.field])
        } else {
          filter.value[props.settings.field] = route.query[props.settings.field]
        }
      }
    }

    const setFilter = () => {
      if (props.settings.field === 'id') {
        filter.value[props.settings.field] = state.value ? Number(state.value) : null
      } else {
        filter.value[props.settings.field] = state.value || null
      }
    }

    const getFilter = () => {
      if (filter.value[props.settings.field]) {
        if (props.settings.field === 'id') {
          state.value = Number(filter.value[props.settings.field])
        } else {
          state.value = filter.value[props.settings.field]
        }
      } else {
        state.value = null
        setFilter()
      }
    }

    watch(state, () => {
      setFilter()
    })

    onMounted(() => {
      getFilter()
    })

    return {
      state
    }
  }
}
</script>
