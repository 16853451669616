<template>
  <nav aria-label="pagination">
    <ul :class="computedClasses">
      <li v-if="doubleArrows" :class="backArrowsClasses">
        <button
            class="page-link"
            @click="setPage(1)"
            :disabled="activePage === 1"
            aria-label="Go to first page"
        >
          <slot name="first-button">&laquo;</slot>
        </button>
      </li>
      <li v-if="arrows" :class="backArrowsClasses">
        <button
            class="page-link"
            @click="setPage(activePage - 1)"
            :disabled="activePage === 1"
            aria-label="Go to previous page"
        >
          <slot name="previous-button">&lsaquo;</slot>
        </button>
      </li>
      <li
          v-if="beforeDots"
          role="separator"
          class="page-item disabled"
      >
        <span class="page-link">…</span>
      </li>

      <li
          v-for="item in items"
          :key="item"
          :class="[{ 'active': activePage === item }, 'page-item']"
      >
        <button
            ref="cpln"
            class="page-link c-page-link-number"
            @click="setPage(item, $event)"
            :aria-label="activePage === item ? `Current page ${item}` : `Go to page ${item}`"
        >
          {{ item }}
        </button>
      </li>

      <li
          v-if="afterDots"
          role="separator"
          class="page-item disabled"
      >
        <span class="page-link">…</span>
      </li>
      <li
          v-if="arrows"
          :class="nextArrowsClasses"
      >
        <button
            class="page-link"
            @click="setPage(activePage + 1)"
            :disabled="activePage === pages"
            aria-label="Go to next page"
        >
          <slot name="next-button">&rsaquo;</slot>
        </button>
      </li>
      <li v-if="doubleArrows" :class="nextArrowsClasses">
        <button
            class="page-link"
            @click="setPage(pages)"
            :disabled="activePage === pages"
            aria-label="Go to last page"
        >
          <slot name="last-button">&raquo;</slot>
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
import { ref, computed, nextTick } from 'vue'

export default {
  name: 'VPagination',
  props: {
    activePage: {
      type: Number,
      default: 1
    },
    pages: {
      type: Number,
      default: 10
    },
    size: {
      type: String,
      validator: val => ['', 'sm', 'lg'].includes(val)
    },
    align: {
      type: String,
      default: 'start',
      validator: val => ['start', 'center', 'end'].includes(val)
    },
    limit: {
      type: Number,
      default: 5
    },
    dots: {
      type: Boolean,
      default: true
    },
    arrows: {
      type: Boolean,
      default: true
    },
    doubleArrows: {
      type: Boolean,
      default: true
    }
  },
  emits: ['paginate'],
  // watch: {
  //   pages: {
  //     immediate: true,
  //     handler (val) {
  //       if (val && val < this.activePage) {
  //         this.$emit('paginate', val, true)
  //       }
  //     }
  //   }
  // },
  setup (props, { emit, root }) {
    const cpln = ref(null)

    const backArrowsClasses = computed(() => ['page-item', { disabled: props.activePage === 1 }])
    const nextArrowsClasses = computed(() => ['page-item', { disabled: props.activePage === props.pages }])
    const computedClasses = computed(() => {
      const sizeClass = props.size ? `pagination-${props.size}` : ''
      return `pagination ${sizeClass} justify-content-${props.align}`
    })
    const showDots = computed(() => props.dots && props.limit > 4 && props.limit < props.pages)
    const maxPrevItems = computed(() => Math.floor((props.limit - 1) / 2))
    const maxNextItems = computed(() => Math.ceil((props.limit - 1) / 2))
    const beforeDots = computed(() => showDots.value && props.activePage > maxPrevItems.value + 1)
    const afterDots = computed(() => showDots.value && props.activePage < props.pages - maxNextItems.value)
    const computedLimit = computed(() => props.limit - afterDots.value - beforeDots.value)
    const range = computed(() => props.activePage + maxNextItems.value)
    const lastItem = computed(() => range.value >= props.pages ? props.pages : range.value - afterDots.value)
    const itemsAmount = computed(() => props.pages < computedLimit.value ? props.pages : computedLimit.value)
    const items = computed(() => {
      if (props.activePage - maxPrevItems.value <= 1) {
        return Array.from({ length: itemsAmount.value }, (v, i) => i + 1)
      } else {
        return Array.from({ length: itemsAmount.value }, (v, i) => {
          return lastItem.value - i
        }).reverse()
      }
    })

    function changeFocus (e) {
      const items = cpln.value.getElementsByClassName('c-page-link-number')
      const focused = Number(e.target.innerHTML)
      nextTick(() => {
        for (let i = 0; i < items.length; i++) {
          if (Number(items[i].innerHTML) === focused) {
            items[i].focus()
          }
        }
      })
    }

    function setPage (number, e = null) {
      if (number === props.activePage) return
      emit('paginate', number, false)
      if (e) changeFocus(e)
    }

    return {
      cpln,
      computedClasses,
      backArrowsClasses,
      setPage,
      beforeDots,
      items,
      afterDots,
      nextArrowsClasses
    }
  }
}
</script>
