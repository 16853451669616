import { ref } from 'vue'
import { useApolloClient } from '@vue/apollo-composable'
import { useErrors } from '@/base/use/errors'

const useGqlQuery = (params) => {
  const apollo = useApolloClient()
  const { setErrors, clearErrors } = useErrors()

  const loading = ref(false)
  const result = ref(null)

  const fetch = (vars) => {
    clearErrors()
    loading.value = true
    apollo.client.query({
      query: params.gqlQuery,
      variables: Object.assign({}, params.gqlVars, vars),
      fetchPolicy: params.fetchPolicy || 'no-cache'
    }).then(response => {
      loading.value = false
      result.value = response.data[params.gqlKey]
    }).catch(e => {
      loading.value = false
      setErrors(e)
    })
  }

  return {
    loading,
    result,
    fetch
  }
}

const useGqlPush = (params) => {
  const apollo = useApolloClient()

  const pushed = ref(false)
  const error = ref(null)

  const push = (obj) => {
    pushed.value = false
    pushed.value = null
    apollo.client.mutate({
      mutation: params.gqlPush,
      variables: { [params.inputKey]: obj }
    }).then(() => {
      pushed.value = true
    }).catch(err => {
      error.value = err
    })
  }

  return {
    pushed,
    push,
    error
  }
}

export {
  useGqlQuery,
  useGqlPush
}
