import { ref, reactive, computed } from 'vue'

const filters = reactive({})
const filter = ref(null)
const _isNeedRerender = ref(false)

export const useFilter = name => {
  if (!filters[name]) filters[name] = {}

  filter.value = filters[name]

  const clear = () => {
    filters[name] = {}
    filter.value = null
  }

  const needRerender = () => {
    _isNeedRerender.value = true
  }

  const needRerenderClear = () => {
    _isNeedRerender.value = false
  }

  const isNeedRerender = computed(() => _isNeedRerender.value)

  return {
    filter,
    clear,
    needRerender,
    needRerenderClear,
    isNeedRerender
  }
}

export const useFilters = () => {
  return filters
}
