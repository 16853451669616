<template>
  <div class="login">
    <div class="circle xx-large shade-1"></div>
    <div class="circle x-large shade-2"></div>
    <div class="circle large shade-3"></div>
    <div class="circle medium shade-4"></div>
    <div class="circle small shade-5"></div>

    <div style="width: 300px; padding: 1rem; z-index: 2000;">

      <span class="label-text">
        Менеджер
        <span class="label-tag-first">VEZDEVOZ.RU</span>
      </span>

      <form v-on:submit.prevent="login">
        <div v-if="errorMessage" style="color: orangered">Неверный логин или пароль!</div>
        <div :class="{snake: error}">
          <VInput type="text" label="" v-model="email" placeholder="Логин" @input="error = false" style="margin-bottom: 5px"/>
          <VInput type="password" label="" v-model="password" placeholder="Пароль" @input="error = false" style="margin-bottom: 10px"/>
        </div>
<!--        <VButton type="submit" color="primary" text="Войти" icon="cisArrowRight"/>-->
        <button class="ui-btn ui-btn-primary" type="submit">войти</button>
      </form>
    </div>
  </div>
  <VLoader v-if="loading"/>
</template>

<script>
import { ApolloClient } from 'apollo-client'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { GC_AUTH_TOKEN, GC_USER_EMAIL, GC_USER_ID, BASE_URI } from '@/base/settings'
import gql from 'graphql-tag'
import VInput from '@/base/components/elements/VInput'
import VLoader from '@/base/components/loader/VLoader'

export default {
  name: 'Login',
  components: { VInput, VLoader },
  data () {
    return {
      email: '',
      password: '',
      error: false,
      loading: false,
      errorMessage: false
    }
  },
  methods: {
    login () {
      this.errorMessage = false
      if (!this.email.length || !this.password.length) {
        this.startSnake()
        this.errorMessage = true
        return
      }
      this.error = false
      this.loading = true

      const httpLinkLogin = new BatchHttpLink({
        uri: BASE_URI + '/q/?',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })

      const apolloClientLogin = new ApolloClient({
        link: httpLinkLogin,
        cache: new InMemoryCache(),
        shouldBatch: false,
        connectToDevTools: false
      })

      apolloClientLogin.mutate({
        mutation: gql`
          mutation ($email: String!, $password: String!) {
            LoginQuery (email: $email, password: $password) {
              id,
              email,
              access_token
            }
          }`,
        variables: {
          email: this.email,
          password: this.password
        }
      }).then((data) => {
        const response = data.data.LoginQuery
        localStorage.setItem(GC_AUTH_TOKEN, response.access_token)
        localStorage.setItem(GC_USER_ID, response.id)
        localStorage.setItem(GC_USER_EMAIL, response.email)
        location.href = '/'
      }).catch(() => {
        this.startSnake()
        this.loading = false
        this.password = ''
        this.errorMessage = true
        localStorage.removeItem(GC_AUTH_TOKEN)
        localStorage.removeItem(GC_USER_ID)
        localStorage.removeItem(GC_USER_EMAIL)
      })
    },
    startSnake: function () {
      this.error = true
      setTimeout(this.stopSnake, 1200)
    },
    stopSnake: function () {
      this.error = false
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  background: aliceblue;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  justify-content: center;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error {
  text-align: center;
}

.label-text {
  display: flex;
  justify-content: space-between;
  color: #535c69;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  font-variant: small-caps;
  .label-tag-first {
    display: contents;
    color: orange;
    font-size: 14px;
    padding-right: .5rem;
  }
  .label-tag-second {
    color: #535c69;
    font-size: 12px;
    margin-top: 3px;
  }
}

.circle{
  position: absolute;
  border-radius: 50%;
  background: white;
  animation: ripple 15s infinite;
  box-shadow: 0 0 1px 0 #508fb9;
}

.small{
  width: 200px;
  height: 200px;
  left: -100px;
  background-color: #009df1;
}

.medium{
  width: 400px;
  height: 400px;
  left: -200px;
}

.large{
  width: 600px;
  height: 600px;
  left: -300px;
}

.x-large{
  width: 800px;
  height: 800px;
  left: -400px;
}

.xx-large{
  width: 1000px;
  height: 1000px;
  left: -500px;
}

.shade-1{
  opacity: 0.2;
}
.shade-2{
  opacity: 0.5;
}

.shade-3{
  opacity: 0.7;
}

.shade-4{
  opacity: 0.8;
}

.shade-5{
  opacity: 0.9;
}

@keyframes ripple{
  0%{
    transform: scale(0.8);
  }

  50%{
    transform: scale(1.2);
  }

  100%{
    transform: scale(0.8);
  }
}
</style>
