const RaceReportList = () => import(/* webpackChunkName: "operating" */ '@/views/operating/report/Index')
// const BpPeriodList = () => import(/* webpackChunkName: "operating" */ '@/views/operating/bp/periods/BpPeriodList')
// const BpPeriodItem = () => import(/* webpackChunkName: "operating" */ '@/views/operating/bp/periods/BpPeriodItem')
// const BpPeriodReport = () => import(/* webpackChunkName: "operating" */ '@/views/operating/bp/periods/BpPeriodReport')
// const SupplierPeriodList = () => import(/* webpackChunkName: "operating" */ '@/views/operating/period/SupplierPeriodList')
// const BotUserList = () => import(/* webpackChunkName: "operating" */ '@/views/operating/period/botuser/BotUserList')
const PlaningIndex = () => import(/* webpackChunkName: "operating" */ '@/views/operating/planing/PlaningIndex')
const ShiftTypeList = () => import(/* webpackChunkName: "operating" */ '@/views/operating/planing/shifttype/ShiftTypeList')

export default [
  {
    path: 'report',
    meta: { roles: ['root', 'boss', 'manager'], groups: [], label: 'Табель учета' },
    name: 'RaceReportList',
    component: RaceReportList,
    children: []
  },
  // {
  //   path: 'bp',
  //   meta: { roles: ['root', 'boss', 'manager'], label: 'Бизнес-процессы' },
  //   component: { template: '<router-view></router-view>' },
  //   children: [
  //     {
  //       path: 'periods',
  //       component: { template: '<router-view></router-view>' },
  //       children: [
  //         {
  //           path: '',
  //           name: 'BpPeriodList',
  //           meta: { roles: ['root', 'boss', 'manager'], groups: [], label: 'Взаиморасчет с перевозчиком' },
  //           component: BpPeriodList
  //         },
  //         {
  //           path: ':code',
  //           props: true,
  //           name: 'BpPeriodItem',
  //           meta: { roles: ['root', 'boss', 'manager'], groups: [], label: 'Документ бизнес-процесса' },
  //           component: BpPeriodItem
  //         },
  //         {
  //           path: 'report/:code',
  //           props: true,
  //           name: 'BpPeriodReport',
  //           meta: { roles: ['root', 'boss', 'manager'], groups: [], label: 'Отчет по перевозчику' },
  //           component: BpPeriodReport
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   path: 'period',
  //   meta: { roles: ['root', 'boss', 'manager'], groups: [], label: 'Расчетные периоды (бот)' },
  //   component: { template: '<router-view></router-view>' },
  //   children: [
  //     {
  //       path: ':param?',
  //       meta: { roles: ['root', 'boss', 'manager'], groups: [] },
  //       name: 'SupplierPeriodList',
  //       component: SupplierPeriodList
  //     },
  //     {
  //       path: 'botuser',
  //       meta: { roles: ['root', 'boss', 'manager'], groups: [], label: 'Бот-пользователи' },
  //       name: 'BotUserList',
  //       component: BotUserList
  //     }
  //   ]
  // },
  {
    path: 'planing',
    meta: { roles: ['root', 'boss', 'manager'], groups: [], label: 'Планирование маршрутов' },
    component: { template: '<router-view></router-view>' },
    children: [
      {
        path: ':param?',
        meta: { roles: ['root', 'boss', 'manager'], groups: [] },
        name: 'PlaningIndex',
        component: PlaningIndex
      },
      {
        path: 'shift/:param?',
        meta: { roles: ['root', 'boss', 'manager'], groups: [], label: 'Типы смен водителей' },
        name: 'ShiftTypeList',
        component: ShiftTypeList
      }
    ]
  }
]
