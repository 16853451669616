<template>
  <VInput
      :full-width="false"
      v-bind="$attrs"
      v-model="from"
      :id="settings.name + '-from'"
      :type="settings.time ? 'datetime-local' : 'date'"
      :label="'Начало периода'"
      :disabled="disabled"
      step="1"
  />
  <VInput
      :full-width="false"
      v-bind="$attrs"
      v-model="to"
      :id="settings.name + '-to'"
      :type="settings.time ? 'datetime-local' : 'date'"
      :label="'Окончание периода'"
      :disabled="disabled"
      step="1"
  />
  <div class="mb-2" v-if="settings.selectors">
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="radio" id="sel1" name="sel" @input="setSelector(1)"/>
      <label for="sel1">Выбрать</label>
    </div>
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="radio" id="sel2" name="sel" @input="setSelector(2)"/>
      <label for="sel2">Сегодня</label>
    </div>
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="radio" id="sel3" name="sel" @input="setSelector(3)"/>
      <label for="sel3">Вчера</label>
    </div>
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="radio" id="sel4" name="sel" @input="setSelector(4)"/>
      <label for="sel4">Текущая неделя</label>
    </div>
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="radio" id="sel5" name="sel" @input="setSelector(5)"/>
      <label for="sel5">Прошлая неделя</label>
    </div>
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="radio" id="sel6" name="sel" @input="setSelector(6)"/>
      <label for="sel6">Текущий месяц</label>
    </div>
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="radio" id="sel7" name="sel" @input="setSelector(7)"/>
      <label for="sel7">Прошлый месяц</label>
    </div>
  </div>
</template>

<script>
import VInput from '../../elements/VInput'
import { ref, watch, computed, onMounted } from 'vue'
import { useFilter } from '@/base/use/filter'
import { useRoute } from 'vue-router'

import moment from 'moment'

export default {
  name: 'VPeriodField',
  components: { VInput },
  props: {
    name: {
      type: String,
      required: true
    },
    settings: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const { filter } = useFilter(props.name)

    const from = ref()
    const to = ref()
    const sel = ref(1)
    const disabled = ref(false)

    const route = useRoute()
    if (route.query) {
      if (route.query.from && route.query.to) {
        filter.value.from = route.query.from
        filter.value.to = route.query.to
      }
      if (route.query._sel) {
        filter.value._sel = route.query._sel
      }
    }

    const _from = computed({
      get: () => {
        if (props.settings.time) return from.value.replace('T', ' ')
        else return from.value + ' 00:00:00'
      },
      set: (value) => {
        if (props.settings.time) from.value = value.replace(' ', 'T')
        else from.value = value.substr(0, 10)
      }
    })

    const _to = computed({
      get: () => {
        if (props.settings.time) return to.value.replace('T', ' ')
        else return to.value + ' 23:59:59'
      },
      set: (value) => {
        if (props.settings.time) to.value = value.replace(' ', 'T')
        else to.value = value.substr(0, 10)
      }
    })

    const setFilter = () => {
      filter.value.from = _from.value
      filter.value.to = _to.value
      if (props.settings.selectors) {
        filter.value._sel = sel.value
      }
    }

    const getFilter = () => {
      if (filter.value.from && filter.value.to) {
        _from.value = filter.value.from
        _to.value = filter.value.to
        if (props.settings.selectors) {
          setSelector(filter.value._sel || 1)
        }
      } else {
        if (props.settings.time) setDateWithTimeFilter()
        else setOnlyDateFilter()

        if (props.settings.selectors) {
          setSelector(props.settings.defaultSelector || 1)
        }
        setFilter()
      }
    }

    function setDateWithTimeFilter () {
      if (props.settings.subMonths) {
        from.value = moment().subtract(props.settings.subMonths, 'month').format('YYYY-MM-DD') + 'T00:00:00'
      } else {
        from.value = moment().subtract(1, 'month').format('YYYY-MM-DD') + 'T00:00:00'
      }
      to.value = moment().format('YYYY-MM-DD') + 'T23:59:59'
    }

    function setOnlyDateFilter () {
      if (props.settings.subMonths) {
        from.value = moment().subtract(props.settings.subMonths, 'month').format('YYYY-MM-DD')
      } else {
        from.value = moment().subtract(1, 'month').format('YYYY-MM-DD')
      }
      to.value = moment().format('YYYY-MM-DD')
    }

    function setSelector (number) {
      const n = parseInt(number)
      switch (n) {
      case 1 : {
        sel.value = n
        disabled.value = false
        break
      }
      case 2: {
        sel.value = n
        if (!props.settings.time) {
          from.value = moment().format('YYYY-MM-DD')
          to.value = moment().format('YYYY-MM-DD')
        } else {
          from.value = moment().format('YYYY-MM-DD') + 'T00:00:00'
          to.value = moment().format('YYYY-MM-DD') + 'T23:59:59'
        }
        disabled.value = true
        break
      }
      case 3: {
        sel.value = n
        if (!props.settings.time) {
          from.value = moment().subtract(1, 'day').format('YYYY-MM-DD')
          to.value = moment().subtract(1, 'day').format('YYYY-MM-DD')
        } else {
          from.value = moment().subtract(1, 'day').format('YYYY-MM-DD') + 'T00:00:00'
          to.value = moment().subtract(1, 'day').format('YYYY-MM-DD') + 'T23:59:59'
        }
        disabled.value = true
        break
      }
      case 4: {
        sel.value = n
        if (!props.settings.time) {
          from.value = moment().startOf('isoWeek').format('YYYY-MM-DD')
          to.value = moment().endOf('isoWeek').format('YYYY-MM-DD')
        } else {
          from.value = moment().startOf('isoWeek').format('YYYY-MM-DD') + 'T00:00:00'
          to.value = moment().endOf('isoWeek').format('YYYY-MM-DD') + 'T23:59:59'
        }
        disabled.value = true
        break
      }
      case 5: {
        sel.value = n
        if (!props.settings.time) {
          from.value = moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD')
          to.value = moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD')
        } else {
          from.value = moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD') + 'T00:00:00'
          to.value = moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD') + 'T23:59:59'
        }
        disabled.value = true
        break
      }
      case 6: {
        sel.value = n
        if (!props.settings.time) {
          from.value = moment().startOf('month').format('YYYY-MM-DD')
          to.value = moment().endOf('month').format('YYYY-MM-DD')
        } else {
          from.value = moment().startOf('month').format('YYYY-MM-DD') + 'T00:00:00'
          to.value = moment().endOf('month').format('YYYY-MM-DD') + 'T23:59:59'
        }
        disabled.value = true
        break
      }
      case 7: {
        sel.value = n
        if (!props.settings.time) {
          from.value = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
          to.value = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
        } else {
          from.value = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD') + 'T00:00:00'
          to.value = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD') + 'T23:59:59'
        }
        disabled.value = true
        break
      }
      }
      document.getElementById('sel1').checked = false
      document.getElementById('sel2').checked = false
      document.getElementById('sel3').checked = false
      document.getElementById('sel4').checked = false
      document.getElementById('sel5').checked = false
      document.getElementById('sel6').checked = false
      document.getElementById('sel7').checked = false
      document.getElementById('sel' + sel.value).checked = true
    }

    watch([from, to], () => {
      setFilter()
    })

    onMounted(() => {
      getFilter()
    })

    return {
      from,
      to,
      setSelector,
      disabled
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  font-size: 12px;
  color: #4f5d73;
  display: block;
  width: 100%;
  margin: 0;
  padding-top: 4px;
}
</style>
