<template>
  <div class="global-errors">
    <div class="errors" v-if="errors.length">
      <div v-for="(e, i) in errors" :key="'error_' + i" v-html="e"></div>
      <div class="errors-close" @click="clearErrors">+</div>
    </div>
  </div>
</template>

<script>
import { useErrors } from '@/base/use/errors'

export default {
  name: 'Errors',
  setup () {
    const { errors, clearErrors } = useErrors()

    return {
      errors,
      clearErrors
    }
  }
}
</script>

<style lang="scss" scoped>
.global-errors {
  position: fixed;
  width: 100%;
  top: 98px;
  z-index: 1000;
  padding: 0;

.errors {
  display: block;
  position: relative;
  background-color: #e55353;
  color: #fff;
  font-weight: bold;
  padding: 1rem;
  padding-left: 4rem !important;
  line-height: 1.2;
  font-size: 12px;

  .errors-close {
    font-size: 30px;
    border-radius: 20px;
    position: absolute;
    top: 6px;
    left: 28px;
    cursor: pointer;
    transform: rotate(45deg);
    color: #fff;
    transition: all 280ms ease-in-out;

    &:hover {
      transform: rotate(45deg) scale(1.1);
    }
  }
}
}
</style>
