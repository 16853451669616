const AutoBrandList = () => import(/* webpackChunkName: "transport" */ '@/views/transport/brand/AutoBrandList')
const AutoModelList = () => import(/* webpackChunkName: "transport" */ '@/views/transport/brand/model/AutoModelList')
// const AutoServiceList = () => import(/* webpackChunkName: "transport" */ '@/views/transport/service/AutoServiceList')
const ToWorkList = () => import(/* webpackChunkName: "transport" */ '@/views/transport/to/work/ToWorkList')
const To = () => import(/* webpackChunkName: "transport" */ '@/views/transport/to/To')
const ToEdit = () => import(/* webpackChunkName: "transport" */ '@/views/transport/to/edit/ToEdit')
// const AutoVideoReportList = () => import(/* webpackChunkName: "transport" */ '@/views/transport/v-report/AutoVideoReportList')
// const Bot2UserList = () => import(/* webpackChunkName: "transport" */ '@/views/transport/v-report/bot2user/Bot2UserList')
const TrackerList = () => import(/* webpackChunkName: "transport" */ '@/views/transport/tracker/TrackerList')
// const FuelCardList = () => import(/* webpackChunkName: "transport" */ '@/views/transport/fuelcard/FuelCardList')
const FuelPointsTemp = () => import(/* webpackChunkName: "transport" */ '@/views/transport/fueling/FuelPointsTemp')
const FuelPointsTempDetail = () => import(/* webpackChunkName: "transport" */ '@/views/transport/fueling/FuelPointsTempDetail')
const FuelPointsMonths = () => import(/* webpackChunkName: "transport" */ '@/views/transport/fueling/FuelPointsMonths')
const FuelPointsMonthsDetail = () => import(/* webpackChunkName: "transport" */ '@/views/transport/fueling/FuelPointsMonthsDetail')
const FuelsList = () => import(/* webpackChunkName: "transport" */ '@/views/transport/fueling/fuels/FuelsList')
const ShtrihmList = () => import(/* webpackChunkName: "transport" */ '@/views/transport/shtrihm/ShtrihmList')
const ShtrihmDistanceList = () => import(/* webpackChunkName: "transport" */ '@/views/transport/shtrihm/ShtrihmDistanceList')
// const DriverDocuments = () => import(/* webpackChunkName: "transport" */ '@/views/transport/docs/driver/DriverDocuments')
// const AutoDocuments = () => import(/* webpackChunkName: "transport" */ '@/views/transport/docs/auto/AutoDocuments')
const AutoDistanceList = () => import(/* webpackChunkName: "transport" */ '@/views/transport/to/distance/AutoDistanceList')

export default [
  {
    path: 'brand',
    meta: { roles: ['root', 'boss'], groups: ['transport'], label: 'Производители' },
    component: { template: '<router-view></router-view>' },
    children: [
      {
        path: ':param?',
        meta: { roles: ['root', 'boss'], groups: ['transport'] },
        name: 'AutoBrandList',
        component: AutoBrandList
      },
      {
        path: 'model/:param?',
        meta: { roles: ['root', 'boss'], groups: ['transport'], label: 'Модели' },
        name: 'AutoModelList',
        component: AutoModelList
      }
    ]
  },
  // {
  //   path: 'service',
  //   meta: { roles: ['root', 'boss'], groups: ['transport'], label: 'Тех. обслуживание (старое)' },
  //   name: 'AutoServiceList',
  //   component: AutoServiceList,
  //   children: [
  //   ]
  // },
  {
    path: 'to',
    meta: { roles: ['root', 'boss'], groups: ['transport'], label: 'ТО' },
    component: { template: '<router-view></router-view>' },
    children: [
      {
        path: '',
        meta: { roles: ['root', 'boss'], groups: ['transport'] },
        name: 'To',
        component: To
      },
      {
        path: 'distance',
        meta: { roles: ['root', 'boss'], groups: ['transport'], label: 'Пробеги' },
        name: 'AutoDistanceList',
        component: AutoDistanceList
      },
      {
        path: ':code',
        props: true,
        meta: { roles: ['root', 'boss'], groups: ['transport'], label: 'Редактор' },
        name: 'ToEdit',
        component: ToEdit
      }
    ]
  },
  {
    path: 'to-work/:param?',
    meta: { roles: ['root', 'boss'], groups: ['transport'], label: 'Справочник ТО' },
    name: 'ToWorkList',
    component: ToWorkList,
    children: [
    ]
  },
  // {
  //   path: 'v-report',
  //   meta: { roles: ['root', 'boss'], groups: ['transport'], label: 'Видеоосмотры' },
  //   component: { template: '<router-view></router-view>' },
  //   children: [
  //     {
  //       path: ':param?',
  //       meta: { roles: ['root', 'boss'], groups: ['transport'] },
  //       name: 'AutoVideoReportList',
  //       component: AutoVideoReportList
  //     },
  //     {
  //       path: 'bot2user',
  //       meta: { roles: ['root', 'boss'], groups: ['transport'], label: 'Бот-пользователи' },
  //       name: 'Bot2UserList',
  //       component: Bot2UserList
  //     }
  //   ]
  // },
  {
    path: 'tracker/:param?',
    meta: { roles: ['root', 'boss'], groups: ['transport'], label: 'Трекеры' },
    name: 'TrackerList',
    component: TrackerList,
    children: [
    ]
  },
  // {
  //   path: 'fuelcard/:param?',
  //   meta: { roles: ['root', 'boss'], groups: ['transport'], label: 'Топливные карты' },
  //   name: 'FuelCardList',
  //   component: FuelCardList,
  //   children: [
  //   ]
  // },
  {
    path: 'fueling',
    meta: { roles: ['root', 'boss'], groups: ['transport'], label: 'Топливные отчеты' },
    component: { template: '<router-view></router-view>' },
    children: [
      {
        path: 'temp',
        meta: { roles: ['root', 'boss'], groups: ['transport'], label: 'Временные отчеты' },
        name: 'FuelPointsTemp',
        component: FuelPointsTemp
      },
      {
        path: 'temp/detail',
        meta: { roles: ['root', 'boss'], groups: ['transport'], label: 'Временные отчеты (детально)' },
        name: 'FuelPointsTempDetail',
        component: FuelPointsTempDetail
      },
      {
        path: 'months',
        meta: { roles: ['root', 'boss'], groups: ['transport'], label: 'Отчеты по месяцам' },
        name: 'FuelPointsMonths',
        component: FuelPointsMonths
      },
      {
        path: 'months/detail',
        meta: { roles: ['root', 'boss'], groups: ['transport'], label: 'Отчеты по месяцам (детально)' },
        name: 'FuelPointsMonthsDetail',
        component: FuelPointsMonthsDetail
      },
      {
        path: 'fuels',
        meta: { roles: ['root', 'boss'], groups: ['transport'], label: 'Группы и марки топлива' },
        name: 'FuelsList',
        component: FuelsList
      }
    ]
  },
  {
    path: 'shtrihm',
    meta: { roles: ['root', 'boss'], groups: ['transport'], label: 'Штрих-М данные' },
    component: { template: '<router-view></router-view>' },
    children: [
      {
        path: '',
        meta: { roles: ['root', 'boss'], groups: ['transport'] },
        name: 'ShtrihmList',
        component: ShtrihmList
      },
      {
        path: 'distance',
        meta: { roles: ['root', 'boss'], groups: ['transport'], label: 'Пробеги' },
        component: { template: '<router-view></router-view>' },
        children: [
          {
            props: true,
            path: ':shtrihmId',
            meta: { roles: ['root', 'boss'], groups: ['transport'] },
            name: 'ShtrihmDistanceList',
            component: ShtrihmDistanceList
          }
        ]
      }
    ]
  }
  // {
  //   path: 'driver-docs',
  //   meta: { roles: ['root', 'boss'], groups: ['transport'], label: 'Документы водителей' },
  //   name: 'DriverDocuments',
  //   component: DriverDocuments
  // },
  // {
  //   path: 'auto-docs',
  //   meta: { roles: ['root', 'boss'], groups: ['transport'], label: 'Документы автомобилей' },
  //   name: 'AutoDocuments',
  //   component: AutoDocuments
  // }
]
