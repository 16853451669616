<template>
  <div class="panel-wrapper" :style="{ display }">
    <div class="side-panel" :style="{ right }">
      <div class="side-panel-label" :class="label">
        <div class="side-panel-close-btn" @click="hide">
          <div class="side-panel-close-btn-inner"></div>
        </div>
        <span class="side-panel-label-text"></span>
      </div>
      <div class="side-title" :class="border">
        <div class="title-text">
          {{ titleLabel }}
        </div>
        <div class="title-btn">
          <button :class="'btn btn-' + color + ' btn-sm'" @click="save">{{ buttonText }}</button>
        </div>
      </div>
      <div class="errors" v-if="errors.length">
        <div v-for="(e, i) in errors" :key="'error_' + i" v-html="e"></div>
        <div class="errors-close" @click="clearErrors()">+</div>
      </div>
      <div class="side-panel-content">
        <slot v-if="item"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, watchEffect } from 'vue'
import { useGqlQuery, useGqlPush } from '../../use/gql'
import { useItem } from '../../use/edit'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'

export default {
  name: 'EditContainer',
  props: {
    param: {
      type: Object,
      required: true
    },
    gqlKey: {
      type: String,
      required: true
    },
    gqlQuery: {
      type: Object,
      required: true
    },
    gqlVars: {
      type: Object,
      required: true
    },
    gqlPush: {
      type: Object,
      required: true
    },
    inputKey: {
      type: String,
      required: true
    },
    setItem: {
      type: Function,
      required: true
    },
    newItem: {
      type: Function,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    validate: {
      type: Function,
      required: true
    },
    onCloseRouteBack: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['after-push'],
  setup (props, { emit }) {
    const router = useRouter()
    const { item, visible } = useItem()
    const toast = useToast()

    const errors = ref([])
    const display = ref('none')
    const right = ref('-60%')
    const titleLabel = ref('')
    const color = ref('')
    const border = ref('')
    const label = ref('')
    const buttonText = ref('')

    const { loading, result, fetch } = useGqlQuery({
      gqlKey: props.gqlKey,
      gqlQuery: props.gqlQuery,
      gqlVars: props.gqlVars
    })

    const { pushed, push, error } = useGqlPush({
      gqlPush: props.gqlPush,
      inputKey: props.inputKey
    })

    const open = () => {
      right.value = '0'
    }

    const close = () => {
      display.value = 'none'
      visible.value = false
    }

    const hide = () => {
      item.value = null
      right.value = '-60%'
      setTimeout(close, 200)
    }

    const clearErrors = () => {
      errors.value = []
    }

    watchEffect(() => {
      if (visible.value) {
        clearErrors()
        display.value = 'block'
        setTimeout(open, 100)
        if (props.param.val !== 'add') {
          const identy = props.param.key === 'id' ? parseInt(props.param.val) : props.param.val

          fetch(Object.assign(props.gqlVars, { [props.param.key]: identy }))
          titleLabel.value = `Редактировать ${props.title}`
          color.value = 'primary'
          border.value = 'side-title-edit'
          label.value = 'bg-primary'
          buttonText.value = 'Сохранить'
        } else {
          item.value = props.newItem()
          titleLabel.value = `Добавить ${props.title}`
          color.value = 'success'
          border.value = 'side-title-add'
          label.value = 'bg-success'
          buttonText.value = 'Добавить'
        }
      } else {
        if (props.onCloseRouteBack) router.back()
      }
    })

    watch(result, (val) => {
      if (val.items[0]) {
        item.value = props.setItem(val.items[0])
      } else {
        errors.value.push(`Not found ${props.param.key} ${props.param.val}`)
      }
    })

    const save = () => {
      clearErrors()
      const e = props.validate(item.value)
      if (e.length) errors.value = e
      else push(item.value)
    }

    watchEffect(() => {
      if (pushed.value) {
        hide()
        if (props.param.val !== 'add') {
          toast('Сохранено!', { timeout: 1500 })
        } else {
          toast.success('Добавлено!', { timeout: 1500 })
        }
        setTimeout(() => {
          emit('after-push')
        }, 200)
      }
    })

    watchEffect(() => {
      if (error.value) {
        const e1 = error.value.graphQLErrors.map(x => x.message)
        const e2 = error.value.graphQLErrors.map(x => x.debugMessage)
        errors.value.push(...e1, ...e2)
        error.value = null
      }
    })

    return {
      errors,
      display,
      right,
      result,
      loading,
      open,
      close,
      hide,
      clearErrors,
      item,
      titleLabel,
      color,
      border,
      label,
      buttonText,
      save
    }
  }
}
</script>

<style lang="scss" scoped>
.panel-wrapper {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 6000;

  .side-panel {
    position: fixed;
    z-index: 3000;
    top: 0;
    right: -60%;
    background: #eef2f4;
    transition: all 0.2s;
    width: 90%;
    max-width: 50%;
    height: 100vh;
    box-shadow: 0 0 20px rgba(0, 0, 0, .5);
    color: #000;
    padding: 0;
    margin: 0;

    .side-title {
      display: flex;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      justify-content: space-between;
      padding: 0 1rem;
      height: 49px;

      .title-text {
        display: inline-block;
        font-size: 16px;
        line-height: 50px;
        font-weight: 700;
        color: #535c69;
      }

      .title-btn {
        display: inline-block;
        line-height: 50px;

      }
    }

    .side-title-add {
      border-bottom-color: #01cd74;
    }

    .side-title-edit {
      border-bottom-color: #1cc7d0;
    }

    .errors {
      display: block;
      position: relative;
      background-color: #ff6c5f;
      color: #fff;
      font-weight: bold;
      padding: 1rem;
      padding-left: 4rem !important;
      line-height: 1.2;
      font-size: 12px;

      .errors-close {
        font-size: 30px;
        border-radius: 20px;
        position: absolute;
        z-index: 1000;
        top: 6px;
        left: 28px;
        cursor: pointer;
        transform: rotate(45deg);
        color: #fff;
        transition: all 280ms ease-in-out;

        &:hover {
          transform: rotate(45deg) scale(1.1);
        }
      }
    }

    .side-panel-label {
      display: flex;
      position: absolute;
      left: 0;
      top: 30px;
      min-width: 30px;
      height: 38px;
      padding-right: 5px;
      background: rgba(47, 198, 246, .95);
      border-top-left-radius: 19px;
      border-bottom-left-radius: 19px;
      white-space: nowrap;
      overflow: hidden;
      transition: top 0.3s;
      box-shadow: inset -6px 0 8px -10px rgba(0, 0, 0, 0.95);
      z-index: 1;
      transform: translateX(-100%);
      cursor: pointer;
    }

    .side-panel-label-text {
      display: inline-block;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
      height: 100%;
      margin-left: 2px;
      margin-right: 8px;
      line-height: 38px;
      vertical-align: top;
      font-family: "OpenSans-Bold", Helvetica, Arial, sans-serif;
      font-size: 11px;
      text-transform: uppercase;
      color: #fff;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 700;
    }

    .side-panel-close-btn {
      display: inline-block;
      width: 34px;
      height: 38px;
      vertical-align: top;
      border-radius: 19px;
      transition: all .2s;
    }

    .side-panel-close-btn-inner {
      position: relative;
      width: 25px;
      height: 25px;
      margin-top: 6px;
      margin-left: 7px;
      background-color: transparent;
      border: 2px solid transparent;
      border-radius: 50%;
      opacity: 0.75;
      transition: all 300ms ease;
    }

    .side-panel-close-btn-inner:after, .side-panel-close-btn-inner:before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 14px;
      height: 2px;
      background-color: #fff;
      content: "";
    }

    .side-panel-close-btn-inner:before {
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
      -moz-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
      -ms-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
      -o-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
      transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    }

    .side-panel-close-btn-inner:after {
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      -moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      -o-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
    }

    .side-panel-label:hover .side-panel-close-btn-inner {
      border: 2px solid rgba(255, 255, 255, 0.4);
      opacity: 1;
    }

    .side-panel-content {
      width: 100%;
      height: 95%;
      padding: 1rem;
      overflow-y: auto;
    }
  }
}
</style>
