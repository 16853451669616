import { cilBug } from '@coreui/icons-pro/js/linear/cil-bug'
import { cilHome } from '@coreui/icons-pro/js/linear/cil-home'
import { cilUser } from '@coreui/icons-pro/js/linear/cil-user'
import { cilSettings } from '@coreui/icons-pro/js/linear/cil-settings'
import { cilDollar } from '@coreui/icons-pro/js/linear/cil-dollar'
import { cilBusAlt } from '@coreui/icons-pro/js/linear/cil-bus-alt'
import { cilCalculator } from '@coreui/icons-pro/js/linear/cil-calculator'
import { cilPeople } from '@coreui/icons-pro/js/linear/cil-people'
import { cilWc } from '@coreui/icons-pro/js/linear/cil-wc'
import { cilVoiceOverRecord } from '@coreui/icons-pro/js/linear/cil-voice-over-record'
import { cilLan } from '@coreui/icons-pro/js/linear/cil-lan'
import { cilStorage } from '@coreui/icons-pro/js/linear/cil-storage'
import { cilTransfer } from '@coreui/icons-pro/js/linear/cil-transfer'
import { cilArrowLeft } from '@coreui/icons-pro/js/linear/cil-arrow-left'
import { cilArrowRight } from '@coreui/icons-pro/js/linear/cil-arrow-right'
import { cilLoop } from '@coreui/icons-pro/js/linear/cil-loop'
import { cilMoney } from '@coreui/icons-pro/js/linear/cil-money'
import { cilBriefcase } from '@coreui/icons-pro/js/linear/cil-briefcase'
import { cilAddressBook } from '@coreui/icons-pro/js/linear/cil-address-book'
import { cilInfo } from '@coreui/icons-pro/js/linear/cil-info'
import { cilCalendar } from '@coreui/icons-pro/js/linear/cil-calendar'
import { cilDescription } from '@coreui/icons-pro/js/linear/cil-description'
import { cilGarage } from '@coreui/icons-pro/js/linear/cil-garage'
import { cilCarAlt } from '@coreui/icons-pro/js/linear/cil-car-alt'
import { cilLibraryAdd } from '@coreui/icons-pro/js/linear/cil-library-add'
import { cilLibrary } from '@coreui/icons-pro/js/linear/cil-library'
import { cilCode } from '@coreui/icons-pro/js/linear/cil-code'
import { cilPlus } from '@coreui/icons-pro/js/linear/cil-plus'
import { cilMinus } from '@coreui/icons-pro/js/linear/cil-minus'
import { cilXCircle } from '@coreui/icons-pro/js/linear/cil-x-circle'
import { cilBookmark } from '@coreui/icons-pro/js/linear/cil-bookmark'
import { cilList } from '@coreui/icons-pro/js/linear/cil-list'
import { cilTrash } from '@coreui/icons-pro/js/linear/cil-trash'
import { cilPencil } from '@coreui/icons-pro/js/linear/cil-pencil'
import { cilMagnifyingGlass } from '@coreui/icons-pro/js/linear/cil-magnifying-glass'
import { cilLockLocked } from '@coreui/icons-pro/js/linear/cil-lock-locked'
import { cilAt } from '@coreui/icons-pro/js/linear/cil-at'
import { cilAccountLogout } from '@coreui/icons-pro/js/linear/cil-account-logout'
import { cilBook } from '@coreui/icons-pro/js/linear/cil-book'
import { cilFile } from '@coreui/icons-pro/js/linear/cil-file'
import { cilArrowThickToBottom } from '@coreui/icons-pro/js/linear/cil-arrow-thick-to-bottom'
import { cilSquare } from '@coreui/icons-pro/js/linear/cil-square'
import { cilActionUndo } from '@coreui/icons-pro/js/linear/cil-action-undo'
import { cilGraph } from '@coreui/icons-pro/js/linear/cil-graph'
import { cilCast } from '@coreui/icons-pro/js/linear/cil-cast'
import { cilGrid } from '@coreui/icons-pro/js/linear/cil-grid'
import { cilArrowCircleBottom } from '@coreui/icons-pro/js/linear/cil-arrow-circle-bottom'
import { cilArrowCircleTop } from '@coreui/icons-pro/js/linear/cil-arrow-circle-top'
import { cilArrowCircleRight } from '@coreui/icons-pro/js/linear/cil-arrow-circle-right'
import { cilArrowCircleLeft } from '@coreui/icons-pro/js/linear/cil-arrow-circle-left'
import { cilTask } from '@coreui/icons-pro/js/linear/cil-task'
import { cilCaretTop } from '@coreui/icons-pro/js/linear/cil-caret-top'
import { cilReload } from '@coreui/icons-pro/js/linear/cil-reload'
import { cilCompass } from '@coreui/icons-pro/js/linear/cil-compass'
import { cilShortText } from '@coreui/icons-pro/js/linear/cil-short-text'
import { cilBath } from '@coreui/icons-pro/js/linear/cil-bath'
import { cilFunctionsAlt } from '@coreui/icons-pro/js/linear/cil-functions-alt'
import { cilShower } from '@coreui/icons-pro/js/linear/cil-shower'
import { cilAvTimer } from '@coreui/icons-pro/js/linear/cil-av-timer'
import { cilGrain } from '@coreui/icons-pro/js/linear/cil-grain'
import { cilMediaPlay } from '@coreui/icons-pro/js/linear/cil-media-play'
import { cilCameraControl } from '@coreui/icons-pro/js/linear/cil-camera-control'
import { cilFilter } from '@coreui/icons-pro/js/linear/cil-filter'
import { cilAlarm } from '@coreui/icons-pro/js/linear/cil-alarm'
import { cilLoopCircular } from '@coreui/icons-pro/js/linear/cil-loop-circular'
import { cilBank } from '@coreui/icons-pro/js/linear/cil-bank'
import { cilLevelDown } from '@coreui/icons-pro/js/linear/cil-level-down'
import { cilSpreadsheet } from '@coreui/icons-pro/js/linear/cil-spreadsheet'
import { cilLocationPin } from '@coreui/icons-pro/js/linear/cil-location-pin'
import { cilCheckAlt } from '@coreui/icons-pro/js/linear/cil-check-alt'
import { cilListRich } from '@coreui/icons-pro/js/linear/cil-list-rich'
import { cilCaretLeft } from '@coreui/icons-pro/js/linear/cil-caret-left'
import { cilCaretRight } from '@coreui/icons-pro/js/linear/cil-caret-right'
import { cilCaretBottom } from '@coreui/icons-pro/js/linear/cil-caret-bottom'
import { cilMenu } from '@coreui/icons-pro/js/linear/cil-menu'
import { cilLightbulb } from '@coreui/icons-pro/js/linear/cil-lightbulb'
import { cilSun } from '@coreui/icons-pro/js/linear/cil-sun'
import { cilCircle } from '@coreui/icons-pro/js/linear/cil-circle'
import { cilFunctions } from '@coreui/icons-pro/js/linear/cil-functions'
import { cilLayers } from '@coreui/icons-pro/js/linear/cil-layers'
import { cilCopy } from '@coreui/icons-pro/js/linear/cil-copy'
import { cilArrowThickLeft } from '@coreui/icons-pro/js/linear/cil-arrow-thick-left'
import { cilArrowThickRight } from '@coreui/icons-pro/js/linear/cil-arrow-thick-right'
import { cilBrightness } from '@coreui/icons-pro/js/linear/cil-brightness'
import { cilHistory } from '@coreui/icons-pro/js/linear/cil-history'
import { cilSync } from '@coreui/icons-pro/js/linear/cil-sync'
import { cilMovie } from '@coreui/icons-pro/js/linear/cil-movie'
import { cilCursor } from '@coreui/icons-pro/js/linear/cil-cursor'
import { cilAirplay } from '@coreui/icons-pro/js/linear/cil-airplay'
import { cilViewModule } from '@coreui/icons-pro/js/linear/cil-view-module'
import { cilApplications } from '@coreui/icons-pro/js/linear/cil-applications'
import { cilAlignCenter } from '@coreui/icons-pro/js/linear/cil-align-center'
import { cilHttps } from '@coreui/icons-pro/js/linear/cil-https'
import { cilUserUnfollow } from '@coreui/icons-pro/js/linear/cil-user-unfollow'
import { cilAirplaneMode } from '@coreui/icons-pro/js/linear/cil-airplane-mode'

// общее
import { cisSave } from '@coreui/icons-pro/js/solid/cis-save'
import { cisFlagRectangle } from '@coreui/icons-pro/js/solid/cis-flag-rectangle'
import { cisFile } from '@coreui/icons-pro/js/solid/cis-file'
import { cisFileExcel } from '@coreui/icons-pro/js/solid/cis-file-excel'
import { cisPhone } from '@coreui/icons-pro/js/solid/cis-phone'
import { cisFilter } from '@coreui/icons-pro/js/solid/cis-filter'
import { cisUpdate } from '@coreui/icons-pro/js/solid/cis-update'
import { cisBan } from '@coreui/icons-pro/js/solid/cis-ban'
import { cisFiberSmart } from '@coreui/icons-pro/js/solid/cis-fiber-smart'
import { cisCheckAlt } from '@coreui/icons-pro/js/solid/cis-check-alt'
import { cisSync } from '@coreui/icons-pro/js/solid/cis-sync'
import { cisActionUndo } from '@coreui/icons-pro/js/solid/cis-action-undo'
import { cisPhoneX } from '@coreui/icons-pro/js/solid/cis-phone-x'
import { cisAsterisk } from '@coreui/icons-pro/js/solid/cis-asterisk'
import { cisClock } from '@coreui/icons-pro/js/solid/cis-clock'
import { cisEye } from '@coreui/icons-pro/js/solid/cis-eye'
import { cisSortAscending } from '@coreui/icons-pro/js/solid/cis-sort-ascending'
import { cisSortDescending } from '@coreui/icons-pro/js/solid/cis-sort-descending'
import { cisRss } from '@coreui/icons-pro/js/solid/cis-rss'
import { cisFlagAlt } from '@coreui/icons-pro/js/solid/cis-flag-alt'
import { cisWarning } from '@coreui/icons-pro/js/solid/cis-warning'
import { cisUser } from '@coreui/icons-pro/js/solid/cis-user'
import { cisUserX } from '@coreui/icons-pro/js/solid/cis-user-x'
import { cisLockLocked } from '@coreui/icons-pro/js/solid/cis-lock-locked'
import { cisX } from '@coreui/icons-pro/js/solid/cis-x'
import { cisSearch } from '@coreui/icons-pro/js/solid/cis-search'
import { cisArrowThickSquareDown } from '@coreui/icons-pro/js/solid/cis-arrow-thick-square-down'
import { cisFunctionsAlt } from '@coreui/icons-pro/js/solid/cis-functions-alt'
import { cisRuble } from '@coreui/icons-pro/js/solid/cis-ruble'
import { cisInfoCircle } from '@coreui/icons-pro/js/solid/cis-info-circle'
import { cisMenu } from '@coreui/icons-pro/js/solid/cis-menu'
import { cisLightbulb } from '@coreui/icons-pro/js/solid/cis-lightbulb'
import { cisBug } from '@coreui/icons-pro/js/solid/cis-bug'
import { cisHandPaper } from '@coreui/icons-pro/js/solid/cis-hand-paper'
import { cisRestore } from '@coreui/icons-pro/js/solid/cis-restore'
import { cisInfo } from '@coreui/icons-pro/js/solid/cis-info'
import { cisNote } from '@coreui/icons-pro/js/solid/cis-note'
import { cisPlus } from '@coreui/icons-pro/js/solid/cis-plus'
import { cisMinus } from '@coreui/icons-pro/js/solid/cis-minus'
import { cisPlusSquare } from '@coreui/icons-pro/js/solid/cis-plus-square'
import { cisMinusSquare } from '@coreui/icons-pro/js/solid/cis-minus-square'
import { cisPencil } from '@coreui/icons-pro/js/solid/cis-pencil'
import { cisTrashX } from '@coreui/icons-pro/js/solid/cis-trash-x'
import { cisList } from '@coreui/icons-pro/js/solid/cis-list'
import { cisLoop } from '@coreui/icons-pro/js/solid/cis-loop'
import { cisCaretLeft } from '@coreui/icons-pro/js/solid/cis-caret-left'
import { cisCaretRight } from '@coreui/icons-pro/js/solid/cis-caret-right'
import { cisArrowLeft } from '@coreui/icons-pro/js/solid/cis-arrow-left'
import { cisArrowRight } from '@coreui/icons-pro/js/solid/cis-arrow-right'

// диспетчерская
import { cisViewModule } from '@coreui/icons-pro/js/solid/cis-view-module'
import { cisViewRow } from '@coreui/icons-pro/js/solid/cis-view-row'
import { cisGrid } from '@coreui/icons-pro/js/solid/cis-grid'
import { cisCallSwapCalls } from '@coreui/icons-pro/js/solid/cis-call-swap-calls'
import { cisLocationPin } from '@coreui/icons-pro/js/solid/cis-location-pin'
import { cisAddressCard } from '@coreui/icons-pro/js/solid/cis-address-card'
import { cisSend } from '@coreui/icons-pro/js/solid/cis-send'
import { cisSettingsAlt } from '@coreui/icons-pro/js/solid/cis-settings-alt'

// транспорт
import { cisCar } from '@coreui/icons-pro/js/solid/cis-car'
import { cisGarage } from '@coreui/icons-pro/js/solid/cis-garage'
import { cisSettings } from '@coreui/icons-pro/js/solid/cis-settings'
import { cisLocationGpsFixed } from '@coreui/icons-pro/js/solid/cis-location-gps-fixed'
import { cisCreditCard } from '@coreui/icons-pro/js/solid/cis-credit-card'
import { cisPetrolStation } from '@coreui/icons-pro/js/solid/cis-petrol-station'
import { cisAlignCenter } from '@coreui/icons-pro/js/solid/cis-align-center'
import { cisMovie } from '@coreui/icons-pro/js/solid/cis-movie'

// бухгалтерия
import { cisBuildingBusiness } from '@coreui/icons-pro/js/solid/cis-building-business'
import { cisStorage } from '@coreui/icons-pro/js/solid/cis-storage'
import { cisFolderShared } from '@coreui/icons-pro/js/solid/cis-folder-shared'
import { cisPlusCircle } from '@coreui/icons-pro/js/solid/cis-plus-circle'
import { cisMinusCircle } from '@coreui/icons-pro/js/solid/cis-minus-circle'
import { cisTransfer } from '@coreui/icons-pro/js/solid/cis-transfer'
import { cisBalanceScale } from '@coreui/icons-pro/js/solid/cis-balance-scale'
import { cisCommute } from '@coreui/icons-pro/js/solid/cis-commute'
import { cisFunctions } from '@coreui/icons-pro/js/solid/cis-functions'
import { cisCash } from '@coreui/icons-pro/js/solid/cis-cash'
import { cisCalculator } from '@coreui/icons-pro/js/solid/cis-calculator'
import { cisDescription } from '@coreui/icons-pro/js/solid/cis-description'
import { cisLayers } from '@coreui/icons-pro/js/solid/cis-layers'
import { cisCode } from '@coreui/icons-pro/js/solid/cis-code'
import { cisCopy } from '@coreui/icons-pro/js/solid/cis-copy'

// склад
import { cisArchive } from '@coreui/icons-pro/js/solid/cis-archive'
import { cisArrowThickLeft } from '@coreui/icons-pro/js/solid/cis-arrow-thick-left'
import { cisArrowThickRight } from '@coreui/icons-pro/js/solid/cis-arrow-thick-right'
import { cisDocument } from '@coreui/icons-pro/js/solid/cis-document'

// администрирование
import { cisPeople } from '@coreui/icons-pro/js/solid/cis-people'
import { cisVoiceOverRecord } from '@coreui/icons-pro/js/solid/cis-voice-over-record'
import { cisWc } from '@coreui/icons-pro/js/solid/cis-wc'
import { cisSpeedometer } from '@coreui/icons-pro/js/solid/cis-speedometer'

//
import { cisBell } from '@coreui/icons-pro/js/solid/cis-bell'
import { cisStar } from '@coreui/icons-pro/js/solid/cis-star'
import { cisLoopSquare } from '@coreui/icons-pro/js/solid/cis-loop-square'
import { cisMobileSms } from '@coreui/icons-pro/js/solid/cis-mobile-sms'

export default Object.assign(
  {},
  {
    cilBug,
    cilHome,
    cilUser,
    cilSettings,
    cilDollar,
    cilBusAlt,
    cilCalculator,
    cilPeople,
    cilWc,
    cilVoiceOverRecord,
    cilLan,
    cilStorage,
    cilTransfer,
    cilArrowLeft,
    cilArrowRight,
    cilLoop,
    cilMoney,
    cilBriefcase,
    cilAddressBook,
    cilInfo,
    cilCalendar,
    cilDescription,
    cilGarage,
    cilCarAlt,
    cilLibraryAdd,
    cilLibrary,
    cilCode,
    cilPlus,
    cilMinus,
    cilBookmark,
    cilList,
    cilTrash,
    cilPencil,
    cilMagnifyingGlass,
    cilLockLocked,
    cilAt,
    cilAccountLogout,
    cilBook,
    cilFile,
    cilArrowThickToBottom,
    cilSquare,
    cilActionUndo,
    cilGraph,
    cilCast,
    cilGrid,
    cilArrowCircleBottom,
    cilArrowCircleTop,
    cilArrowCircleRight,
    cilArrowCircleLeft,
    cilTask,
    cilCaretTop,
    cilXCircle,
    cilReload,
    cilCompass,
    cilShortText,
    cilBath,
    cilFunctionsAlt,
    cilShower,
    cilAvTimer,
    cilGrain,
    cilMediaPlay,
    cilCameraControl,
    cilFilter,
    cilAlarm,
    cilLoopCircular,
    cilBank,
    cilLevelDown,
    cilSpreadsheet,
    cilLocationPin,
    cilCheckAlt,
    cilListRich,
    cilCaretLeft,
    cilCaretRight,
    cilCaretBottom,
    cilMenu,
    cilLightbulb,
    cilSun,
    cilCircle,
    cilFunctions,
    cilLayers,
    cilCopy,
    cilArrowThickLeft,
    cilArrowThickRight,
    cilBrightness,
    cilHistory,
    cilSync,
    cilMovie,
    cilCursor,
    cilAirplay,
    cilViewModule,
    cilApplications,
    cilAlignCenter,
    cilHttps,
    cilUserUnfollow,
    cilAirplaneMode,

    // общее
    cisSave,
    cisFlagRectangle,
    cisFile,
    cisFileExcel,
    cisPhone,
    cisFilter,
    cisUpdate,
    cisBan,
    cisFiberSmart,
    cisCheckAlt,
    cisSync,
    cisActionUndo,
    cisPhoneX,
    cisAsterisk,
    cisClock,
    cisEye,
    cisSortAscending,
    cisSortDescending,
    cisRss,
    cisFlagAlt,
    cisWarning,
    cisUser,
    cisUserX,
    cisLockLocked,
    cisX,
    cisSearch,
    cisArrowThickSquareDown,
    cisFunctionsAlt,
    cisRuble,
    cisInfoCircle,
    cisMenu,
    cisLightbulb,
    cisBug,
    cisHandPaper,
    cisRestore,
    cisInfo,
    cisNote,
    cisPlus,
    cisMinus,
    cisPlusSquare,
    cisMinusSquare,
    cisPencil,
    cisTrashX,
    cisList,
    cisLoop,
    cisCaretLeft,
    cisCaretRight,
    cisArrowLeft,
    cisArrowRight,

    // диспетчерская
    cisViewModule,
    cisViewRow,
    cisGrid,
    cisCallSwapCalls,
    cisLocationPin,
    cisAddressCard,
    cisSend,
    cisSettingsAlt,

    // транспорт
    cisCar,
    cisGarage,
    cisSettings,
    cisLocationGpsFixed,
    cisCreditCard,
    cisPetrolStation,
    cisAlignCenter,
    cisMovie,

    // бухгалтерия
    cisBuildingBusiness,
    cisFolderShared,
    cisPlusCircle,
    cisMinusCircle,
    cisTransfer,
    cisBalanceScale,
    cisCommute,
    cisFunctions,
    cisCash,
    cisCalculator,
    cisDescription,
    cisLayers,
    cisCode,
    cisCopy,

    // админитрирование
    cisPeople,
    cisVoiceOverRecord,
    cisWc,
    cisSpeedometer,

    // склад
    cisArchive,
    cisStorage,
    cisArrowThickLeft,
    cisArrowThickRight,
    cisDocument,

    //
    cisBell,
    cisStar,
    cisLoopSquare,
    cisMobileSms
  }
)
