<template>
  <div :class="classes">
    <label>
      {{ label }}
      <span v-if="required" class="text-danger"><i class="fw-normal" :style="{ 'font-size': '12px' }">*</i></span>
      <select
          v-bind="$attrs"
          v-model="state"
      >
        <option v-if="placeholder" :value="null" selected hidden>{{ placeholder }}</option>
        <template v-for="(option, key) in options">
          <option
              v-if="returnOption"
              :value="option"
              :disabled="option.disabled"
              :selected="option === state"
              :data-key="key"
              :key="'opt1'+key"
          >
            {{ option.label || option.value }}
          </option>
          <option
              v-else-if="typeof option === 'object'"
              :value="option.value"
              :disabled="option.disabled"
              :selected="option.value === state"
              :data-key="key"
              :key="'opt2'+key"
          >
            {{ option.label || option.value }}
          </option>
          <option
              v-else
              :value="option"
              :selected="option === state"
              :data-key="key"
              :key="'opt3'+key"
          >
            {{ option }}
          </option>
        </template>
      </select>
    </label>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'VSelect',
  props: {
    modelValue: {
      type: [Object, String, Number, Boolean],
      required: false,
      default: null
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    options: {
      type: [Array, Object],
      required: true
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    returnOption: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props, { emit }) {
    const state = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })

    const classes = computed(() => props.fullWidth ? 'input-full-wrapper' : 'input-wrapper')

    return {
      state,
      classes
    }
  }
}
</script>
