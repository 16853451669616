import { ref, watch } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { useRoute } from 'vue-router'

const item = ref(null)
const visible = ref(false)

const useEdit = (props) => {
  const route = useRoute()
  const loader = ref(false)
  if (route.params.code !== 'add') {
    loader.value = true
    const { result } = useQuery(props.gqlQuery, props.gqlVars, { fetchPolicy: 'no-cache' })
    watch(result, value => {
      item.value = props.buildItem(value[props.gqlKey].items[0])
      loader.value = false
    })
  } else {
    item.value = props.newItem()
  }

  return {
    item,
    loader
  }
}

const useItem = () => {
  return {
    item,
    visible
  }
}

export {
  useEdit,
  useItem
}
