import { ref, computed } from 'vue'

const loader = ref(false)
const isCubics = ref(false)

export const useLoader = () => {
  const showLoader = (cubics = false) => {
    loader.value = true
    isCubics.value = cubics
  }

  const hideLoader = () => {
    loader.value = false
    isCubics.value = false
  }

  const isLoading = computed(() => loader.value)

  return {
    showLoader,
    hideLoader,
    isLoading,
    isCubics
  }
}
