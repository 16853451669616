<template>
  <main v-if="user">
    <div class="left-wrapper" :style="styleL">
      <Menu/>
    </div>
    <div class="right-wrapper" :style="styleR">
      <Header/>
      <div class="full-content">
        <router-view v-if="access"></router-view>
      </div>
    </div>
    <component :is="modal"/>
  </main>
  <div v-else-if="needLogin">
    <Login/>
  </div>
  <VLoader v-if="isLoading"/>
</template>

<script>
import Menu from './Menu'
import Header from './Header'
import Login from './Login'
import { ref, watch, watchEffect } from 'vue'
import { useUser } from '../use/user'
import { useMenu } from '@/base/use/menu'
import { useRoute, useRouter } from 'vue-router'
import { useModals } from '@/base/use/modal'
import { useLoader } from '@/base/use/loader'
import { useToast } from 'vue-toastification'
import VLoader from '@/base/components/loader/VLoader'

export default {
  name: 'Container',
  components: {
    Menu,
    Header,
    Login,
    VLoader
  },
  setup () {
    const { isLoading } = useLoader()
    const { fetch, user, needLogin, checkAccess } = useUser()
    const { modal } = useModals()
    const toast = useToast()

    fetch()

    const route = useRoute()
    const router = useRouter()

    const access = ref(false)

    watchEffect(() => {
      if (!checkAccess(route.meta)) {
        access.value = false
        router.push('/')
        toast.error('Нет доступа!')
      } else {
        access.value = true
      }
    })

    const { sidebar } = useMenu()
    const styleL = ref({ left: 0 })
    const styleR = ref({ paddingLeft: '300px' })
    watch(sidebar, value => {
      if (value) {
        styleL.value.left = 0
        styleR.value.paddingLeft = '300px'
      } else {
        styleL.value.left = '-300px'
        styleR.value.paddingLeft = 0
      }
    })

    // const isConnected = ref(false)
    // const wsError = ref(false)
    //
    // function connect () {
    //   echoRef.value = createEcho()
    //   echoRef.value.connector.pusher.connection.bind('connected', () => {
    //     /**
    //      * The connection to Channels is open and authenticated with your app.
    //      */
    //     console.log('ws connected!')
    //     isConnected.value = true
    //     wsError.value = false
    //     echoRef.value.private(`personal.${user.value.id}`)
    //       .listen('NtfEvent', (e) => {
    //         if (e) {
    //           pushNotify(e)
    //           notify().success(e.title, e.text)
    //         }
    //       })
    //       .listen('NtfRemoveEvent', (e) => {
    //         if (e) {
    //           removeNotify(e.id)
    //         }
    //       })
    //
    //     if (route.path === '/dispatcher/regular') {
    //       console.log('ws subscribe rr container')
    //       const { setRegularRace, setRegularInformer } = useRacesRegular()
    //       echoRef.value.private('dis.regular')
    //         .listen('BroadcastDataEvent', (e) => {
    //           if (!e.type || !e.data) return
    //           if (e.type === 'dis.regular.race') {
    //             setRegularRace(e.data)
    //           } else if (e.type === 'dis.regular.informer') {
    //             setRegularInformer(e.data)
    //           }
    //         })
    //     } else if (route.path === '/dispatcher/single') {
    //       console.log('ws subscribe sr container')
    //       const { setSingleRace, setSingleInformer } = useRacesSingle()
    //       echoRef.value.private('dis.single')
    //         .listen('BroadcastDataEvent', (e) => {
    //           if (!e.type || !e.data) return
    //           if (e.type === 'dis.single.race') {
    //             setSingleRace(e.data)
    //           } else if (e.type === 'dis.single.informer') {
    //             setSingleInformer(e.data)
    //           }
    //         })
    //     }
    //   })
    //
    //   echoRef.value.connector.pusher.connection.bind('connecting', () => {
    //     /**
    //      * All dependencies have been loaded and Channels is trying to connect.
    //      * The connection will also enter this state when it is trying to reconnect after a connection failure.
    //      */
    //     console.log('connecting...')
    //     wsError.value = true
    //   })
    //
    //   echoRef.value.connector.pusher.connection.bind('unavailable', () => {
    //     /**
    //      *  The connection is temporarily unavailable. In most cases this means that there is no internet connection.
    //      *  It could also mean that Channels is down, or some intermediary is blocking the connection. In this state,
    //      *  pusher-js will automatically retry the connection every 15 seconds.
    //      */
    //     console.log('websocket unavailable')
    //     isConnected.value = false
    //     wsError.value = true
    //   })
    // }
    //
    // watch([isConnected, user], value => {
    //   if (!value[0] && value[1]) {
    //     if (echoRef.value) echoRef.value.disconnect()
    //     console.log('watch connecting...')
    //     connect()
    //   }
    // }, { immediate: true })

    return { user, needLogin, sidebar, styleL, styleR, access, modal, isLoading }
  }
}
</script>
