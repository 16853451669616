export default [
  // {
  //   type: 'menu',
  //   show: true,
  //   collapse: true,
  //   name: 'Производители',
  //   path: 'brand',
  //   comp: 'AutoBrandList',
  //   edit: 'AutoBrandEdit',
  //   icon: 'cisGarage',
  //   items: [
  //     {
  //       type: 'link',
  //       show: true,
  //       name: 'Модели',
  //       path: 'model',
  //       comp: 'AutoModelList',
  //       edit: 'AutoModelEdit',
  //       icon: 'cisList'
  //     }
  //   ]
  // },
  // {
  //   type: 'link',
  //   show: true,
  //   name: 'Тех. обслуживание (старое)',
  //   path: 'service',
  //   comp: 'AutoServiceList',
  //   icon: 'cisSettings'
  // },
  // { type: 'divide' },
  {
    type: 'link',
    show: true,
    name: 'ТО',
    path: 'to',
    comp: 'To',
    icon: 'cisGrid'
  },
  {
    type: 'link',
    show: true,
    name: 'Справочник ТО',
    path: 'to-work',
    comp: 'ToWorkList',
    edit: 'ToWorkEdit',
    icon: 'cisList'
  },
  // {
  //   type: 'menu',
  //   show: true,
  //   collapse: true,
  //   name: 'Видеоосмотры',
  //   path: 'v-report',
  //   comp: 'AutoVideoReportList',
  //   edit: 'AutoVideoReportEdit',
  //   icon: 'cisMovie',
  //   items: [
  //     {
  //       type: 'link',
  //       show: true,
  //       name: 'Бот-пользователи',
  //       path: 'bot2user',
  //       comp: 'Bot2UserList',
  //       icon: 'cisPeople'
  //     }
  //   ]
  // },
  { type: 'divide' },
  {
    type: 'link',
    show: true,
    name: 'Трекеры',
    path: 'tracker',
    comp: 'TrackerList',
    edit: 'TrackerEdit',
    icon: 'cisLocationGpsFixed'
  },
  // {
  //   type: 'link',
  //   show: true,
  //   name: 'Топливные карты',
  //   path: 'fuelcard',
  //   comp: 'FuelCardList',
  //   edit: 'FuelCardEdit',
  //   icon: 'cisCreditCard'
  // },
  {
    type: 'group',
    show: true,
    collapse: true,
    name: 'Топливные отчеты',
    path: 'fueling',
    icon: 'cisPetrolStation',
    items: [
      {
        type: 'link',
        show: true,
        name: 'Временные отчеты',
        path: 'temp',
        comp: 'FuelPointsTemp',
        icon: 'cisDescription'
      },
      {
        type: 'link',
        show: true,
        name: 'Отчеты по месяцам',
        path: 'months',
        comp: 'FuelPointsMonths',
        icon: 'cisDescription'
      },
      {
        type: 'link',
        show: true,
        name: 'Группы и марки топлива',
        path: 'fuels',
        comp: 'FuelsList',
        icon: 'cisList'
      }
    ]
  },
  {
    type: 'custom-link',
    show: true,
    name: 'Штрих-М данные',
    path: 'shtrihm',
    comp: 'ShtrihmList',
    icon: 'cisAlignCenter',
    children: [
      {
        type: 'custom-link',
        name: 'Пробеги',
        path: 'distance',
        comp: 'ShtrihmDistanceList'
      }
    ]
  }
  // { type: 'divide' },
  // {
  //   type: 'link',
  //   show: true,
  //   name: 'Документы водителей',
  //   path: 'driver-docs',
  //   comp: 'DriverDocuments',
  //   icon: 'cisDescription'
  // },
  // {
  //   type: 'link',
  //   show: true,
  //   name: 'Документы автомобилей',
  //   path: 'auto-docs',
  //   comp: 'AutoDocuments',
  //   icon: 'cisDescription'
  // }
]
