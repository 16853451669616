<template>
  <button class="btn btn-primary btn-small me-2" @click="prev">
    <VIcon name="cisArrowLeft"/>
  </button>
  <VInput
      id="month-field"
      :full-width="false"
      :type="'month'"
      v-bind="$attrs"
      v-model="state"
      :label="settings.label"
      :placeholder="settings.placeholder"
  />
  <button class="btn btn-primary btn-small me-2" @click="next">
    <VIcon name="cisArrowRight"/>
  </button>
</template>

<script>
import VInput from '../../elements/VInput'
import VIcon from '../../icon/VIcon'
import { ref, watch, watchEffect, onMounted } from 'vue'
import { useFilter } from '@/base/use/filter'
import { useRoute } from 'vue-router'
import moment from 'moment'

export default {
  name: 'VMonthField',
  components: { VIcon, VInput },
  props: {
    name: {
      type: String,
      required: true
    },
    settings: {
      type: Object,
      required: true
    }
  },
  emits: ['auto-update'],
  setup (props, { emit }) {
    const { filter } = useFilter(props.name)
    const state = ref(null)

    const route = useRoute()
    if (route.query) {
      if (route.query.month) {
        filter.value.month = route.query.month
      }
    }

    const setFilter = () => {
      filter.value.month = state.value
    }

    const getFilter = () => {
      if (filter.value.month) {
        state.value = filter.value.month
      } else {
        state.value = moment().format('YYYY-MM')
        setFilter()
      }
    }

    watchEffect(() => {
      if (filter.value && filter.value.month) {
        state.value = filter.value.month
      }
    })

    watch(state, () => {
      setFilter()
    })

    onMounted(() => {
      getFilter()
    })

    function prev () {
      state.value = moment(state.value).subtract(1, 'month').format('YYYY-MM')
      if (props.settings.autoUpdate) emit('auto-update')
    }

    function next () {
      state.value = moment(state.value).add(1, 'month').format('YYYY-MM')
      if (props.settings.autoUpdate) emit('auto-update')
    }

    return {
      state,
      prev,
      next
    }
  }
}
</script>

<style scoped>
.btn-small {
  height: 28px !important;
  width: 28px !important;
  padding: 0 !important;
}
</style>
