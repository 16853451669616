import { ref, computed } from 'vue'

const errors = ref([])

export const useErrors = () => {
  const setErrors = e => {
    if (typeof e === 'string') {
      errors.value.push(e)
    } else if (e instanceof Array) {
      errors.value = e
    } else if (e.graphQLErrors) {
      const e1 = e.graphQLErrors.map(x => x.message)
      const e2 = e.graphQLErrors.map(x => x.debugMessage)
      errors.value = [...e1, ...e2]
    }
  }

  const clearErrors = () => {
    errors.value = []
  }

  const isErrors = computed(() => !!errors.value.length)

  return {
    errors,
    setErrors,
    clearErrors,
    isErrors
  }
}
