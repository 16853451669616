<template>
  <VCheckbox
      :id="settings.name + '-' + settings.field"
      v-bind="$attrs"
      v-model="state"
      :label="settings.label"
  />
</template>

<script>
import VCheckbox from '../../elements/VCheckbox'
import { ref, watch, onMounted } from 'vue'
import { useFilter } from '@/base/use/filter'
import { useRoute } from 'vue-router'

export default {
  name: 'VCheckboxField',
  components: { VCheckbox },
  props: {
    name: {
      type: String,
      required: true
    },
    settings: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const { filter } = useFilter(props.name)
    const state = ref(false)

    const route = useRoute()
    if (route.query) {
      if (route.query[props.settings.field]) {
        filter.value[props.settings.field] = route.query[props.settings.field]
      }
    }

    const setFilter = () => {
      filter.value[props.settings.field] = state.value || false
    }

    const getFilter = () => {
      if (typeof filter.value[props.settings.field] === 'boolean') {
        state.value = filter.value[props.settings.field]
      } else {
        state.value = props.settings.selected || false
        setFilter()
      }
    }

    watch(state, () => {
      setFilter()
    })

    onMounted(() => {
      getFilter()
    })

    return {
      state
    }
  }
}
</script>
